import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { SortableContainer } from 'react-sortable-hoc';
import classNames from 'classnames';
import { computed } from 'mobx';
import { translate } from 'sulu-admin-bundle/utils';
import SortableBlock from './SortableBlock';
import sortableBlockListStyles from 'sulu-admin-bundle/components/BlockCollection/sortableBlockList.scss';

@observer
class SortableBlockList extends React.Component {
    static defaultProps = {
        blockActions: [],
        disabled: false,
        mode: 'sortable',
        movable: null,
    };

    @computed get blockActions() {
        const { onRemove, blockActions } = this.props;

        if (onRemove) {
            return [
                ...blockActions,
                {
                    type: 'button',
                    icon: 'su-trash-alt',
                    label: translate('sulu_admin.delete'),
                    onClick: onRemove,
                },
            ];
        }

        return blockActions;
    }

    handleExpand = (index) => {
        const { onExpand } = this.props;
        if (onExpand) {
            onExpand(index);
        }
    };

    handleSelect = (index) => {
        const { onSelect } = this.props;
        if (onSelect) {
            onSelect(index);
        }
    };

    handleUnselect = (index) => {
        const { onUnselect } = this.props;
        if (onUnselect) {
            onUnselect(index);
        }
    };

    handleCollapse = (index) => {
        const { onCollapse } = this.props;
        if (onCollapse) {
            onCollapse(index);
        }
    };

    handleSettingsClick = (index) => {
        const { onSettingsClick } = this.props;

        if (onSettingsClick) {
            onSettingsClick(index);
        }
    };

    handleTypeChange = (type, index) => {
        const { onTypeChange } = this.props;

        if (onTypeChange) {
            onTypeChange(type, index);
        }
    };

    render() {
        const {
            disabled,
            expandedBlocks,
            generatedBlockIds,
            icons,
            mode,
            movable,
            onCollapse,
            onExpand,
            onSelect,
            onSettingsClick,
            onUnselect,
            renderBlockContent,
            renderDivider,
            selectedBlocks,
            types,
            value,
        } = this.props;

        const sortableBlockListClass = classNames(sortableBlockListStyles.sortableBlockList, {
            [sortableBlockListStyles.disabled]: disabled,
        });

        return (
            <div className={sortableBlockListClass}>
                {value &&
                    value.map((block, index) => (
                        <Fragment key={index}>
                            <SortableBlock
                                actions={this.blockActions}
                                activeType={block.type}
                                expanded={!disabled && expandedBlocks[index]}
                                icons={icons && icons[index]}
                                index={index}
                                key={generatedBlockIds[index]}
                                mode={mode === 'sortable' && movable !== false ? 'sortable' : mode}
                                onCollapse={onCollapse ? this.handleCollapse : undefined}
                                onExpand={onExpand ? this.handleExpand : undefined}
                                onSelect={onSelect ? this.handleSelect : undefined}
                                onSettingsClick={onSettingsClick ? this.handleSettingsClick : undefined}
                                onTypeChange={this.handleTypeChange}
                                onUnselect={onUnselect ? this.handleUnselect : undefined}
                                renderBlockContent={renderBlockContent}
                                selected={selectedBlocks[index]}
                                sortIndex={index}
                                types={types}
                                value={block}
                            />
                            {renderDivider && index < value.length - 1 && renderDivider(index)}
                        </Fragment>
                    ))}
            </div>
        );
    }
}

export default SortableContainer(SortableBlockList);
