import React from 'react';
import singleMediaSelectionBlockPreviewTransformerStyles from './singleMediaSelectionBlockPreviewTransformer.scss';

export default class SingleMediaSelectionBlockPreviewTransformer {
    imageFormatUrl;

    constructor(imageFormatUrl) {
        this.imageFormatUrl = imageFormatUrl;
    }

    transform(value) {
        const { id } = value;

        if (!id) {
            return null;
        }

        return (
            <img
                className={singleMediaSelectionBlockPreviewTransformerStyles.image}
                key={id}
                src={this.imageFormatUrl.replace(':id', id) + '?locale=en&format=sulu-50x50'}
            />
        );
    }
}
