import { translate } from 'sulu-admin-bundle/utils';
import { action } from 'mobx';
import { AbstractFormToolbarAction } from 'sulu-admin-bundle/views';
import ResourceRequester from 'sulu-admin-bundle/services/ResourceRequester';
import snackbarStore from 'sulu-admin-bundle/stores/snackbarStore';

export default class GenerateNameToolbarAction extends AbstractFormToolbarAction {
    getToolbarItemConfig() {
        return {
            type: 'button',
            label: translate('sulu.reset_password'),
            icon: 'su-lock',
            disabled: false,
            onClick: this.handleClick,
        };
    }

    @action handleClick = () => {
        const { resourceKey, locale, id } = this.resourceFormStore;

        this.loading = true;
        ResourceRequester.post(resourceKey, undefined, {
            action: 'reset-password',
            locale,
            id,
        })
            .then(
                action((response) => {
                    this.loading = false;
                    snackbarStore.add(
                        {
                            type: 'info',
                            text: `${translate('sulu.reset_password.success')} ${response.email}.`,
                        },
                        5000,
                    );
                }),
            )
            .catch(
                action(() => {
                    this.form.errors.push(translate('sulu_admin.unexpected_error'));
                    this.loading = false;
                }),
            );
    };
}
