import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { action, observable, when } from 'mobx';
import { Button, Dialog } from 'sulu-admin-bundle/components';
import { translate } from 'sulu-admin-bundle/utils';
import MediaUploadStore from '../../stores/MediaUploadStore';
import SingleMediaUpload from '../SingleMediaUpload';
import CropOverlay from './CropOverlay';
import FocusPointOverlay from './FocusPointOverlay';
import mediaVersionUploadStyles from './mediaVersionUpload.scss';

@observer
class MediaVersionUpload extends React.Component {
    mediaUploadStore;
    @observable showFocusPointOverlay = false;
    @observable showCropOverlay = false;
    @observable showDeletePreviewDialog = false;
    @observable deletingPreview = false;

    constructor(props) {
        super(props);

        const { resourceStore } = this.props;
        const locale = resourceStore.locale;
        if (!locale) {
            throw new Error('The resourceStore for the MediaVersionUpload must have a locale');
        }

        when(
            () => !resourceStore.loading,
            () => {
                this.mediaUploadStore = new MediaUploadStore(resourceStore.data, locale);
            },
        );
    }

    handleUploadComplete = (media) => {
        this.props.resourceStore.setMultiple(media);
        this.callSuccess();
    };

    handlePreviewUploadClick = (file) => {
        this.mediaUploadStore.updatePreviewImage(file).then(this.callSuccess);
    };

    @action handleDeletePreviewClick = () => {
        this.showDeletePreviewDialog = true;
    };

    @action handleDeletePreviewConfirm = () => {
        this.deletingPreview = true;
        this.mediaUploadStore.deletePreviewImage().then(
            action(() => {
                this.deletingPreview = false;
                this.showDeletePreviewDialog = false;
                this.callSuccess();
            }),
        );
    };

    @action handleDeletePreviewCancel = () => {
        this.showDeletePreviewDialog = false;
    };

    callSuccess = () => {
        const { onSuccess } = this.props;
        if (onSuccess) {
            onSuccess();
        }
    };

    @action handleCropButtonClick = () => {
        this.showCropOverlay = true;
    };

    @action handleCropOverlayClose = () => {
        this.showCropOverlay = false;
    };

    @action handleCropOverlayConfirm = () => {
        this.showCropOverlay = false;
        this.callSuccess();
    };

    @action handleFocusPointButtonClick = () => {
        this.showFocusPointOverlay = true;
    };

    @action handleFocusPointOverlayClose = () => {
        this.showFocusPointOverlay = false;
    };

    @action handleFocusPointOverlayConfirm = () => {
        this.showFocusPointOverlay = false;
        this.callSuccess();
    };

    render() {
        if (!this.mediaUploadStore) {
            return null;
        }
        const { resourceStore } = this.props;

        const {
            data: { adminUrl, url },
            id,
            locale,
        } = resourceStore;

        if (!id) {
            return null;
        }

        if (!locale) {
            throw new Error('The "MediaVersionUpload" field type only works with a locale!');
        }

        return (
            <Fragment>
                <SingleMediaUpload
                    deletable={false}
                    downloadable={false}
                    imageSize="sulu-400x400-inset"
                    mediaUploadStore={this.mediaUploadStore}
                    onUploadComplete={this.handleUploadComplete}
                    uploadText={translate('sulu_media.upload_new_version')}
                />
                <div className={mediaVersionUploadStyles.buttons}>
                    {adminUrl && (
                        <Button
                            icon="su-download"
                            onClick={() => {
                                const baseURL = window.location.origin;
                                window.open(baseURL + adminUrl);
                            }}
                            skin="link"
                        >
                            {translate('sulu_media.download_media')}
                        </Button>
                    )}
                </div>
                <FocusPointOverlay
                    onClose={this.handleFocusPointOverlayClose}
                    onConfirm={this.handleFocusPointOverlayConfirm}
                    open={this.showFocusPointOverlay}
                    resourceStore={resourceStore}
                />
                <CropOverlay
                    id={id}
                    image={adminUrl || url}
                    locale={locale.get()}
                    onClose={this.handleCropOverlayClose}
                    onConfirm={this.handleCropOverlayConfirm}
                    open={this.showCropOverlay}
                />
                <Dialog
                    cancelText={translate('sulu_admin.cancel')}
                    confirmLoading={this.deletingPreview}
                    confirmText={translate('sulu_admin.ok')}
                    onCancel={this.handleDeletePreviewCancel}
                    onConfirm={this.handleDeletePreviewConfirm}
                    open={this.showDeletePreviewDialog}
                    title={translate('sulu_media.delete_preview_image_warning_title')}
                >
                    {translate('sulu_media.delete_preview_image_warning_text')}
                </Dialog>
            </Fragment>
        );
    }
}

export default MediaVersionUpload;
