import React from 'react';
import { observer } from 'mobx-react';

@observer
class MainPage extends React.Component {
    componentDidMount() {
        try {
            this.props.router.navigate('article_list');
        } catch (e) {}
    }

    render() {
        return null;
    }
}

export default MainPage;
