import React, { Fragment } from 'react';
import { action, toJS, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import equals from 'fast-deep-equal';
import { CroppedText, MultiItemSelection } from 'sulu-admin-bundle/components';
import { translate } from 'sulu-admin-bundle/utils';
import { MultiSelectionStore } from 'sulu-admin-bundle/stores';
import { getIconForDisplayOption, getTranslationForDisplayOption } from '../../utils/MediaSelectionHelper';
import MultiMediaSelectionOverlay from '../MultiMediaSelectionOverlay';
import MimeTypeIndicator from '../../components/MimeTypeIndicator';
import multiMediaSelectionStyle from './multiMediaSelection.scss';

const MEDIA_RESOURCE_KEY = 'media';
const THUMBNAIL_SIZE = 'sulu-25x25';

@observer
class MultiMediaSelection extends React.Component {
    static defaultProps = {
        disabled: false,
        displayOptions: [],
        sortable: true,
        types: [],
        value: { displayOption: undefined, ids: [] },
    };

    mediaSelectionStore;
    changeDisposer;

    @observable overlayOpen = false;

    constructor(props) {
        super(props);

        const { locale, value } = this.props;

        this.mediaSelectionStore = new MultiSelectionStore(MEDIA_RESOURCE_KEY, value.ids, locale);
        this.changeDisposer = reaction(
            () => this.mediaSelectionStore.items.map((item) => item.id),
            (loadedMediaIds) => {
                const { onChange, value } = this.props;

                if (!equals(toJS(value.ids), toJS(loadedMediaIds))) {
                    onChange({ ...value, ids: loadedMediaIds });
                }
            },
        );
    }

    componentDidUpdate() {
        const newSelectedIds = toJS(this.props.value.ids);
        const loadedSelectedIds = toJS(this.mediaSelectionStore.items.map((item) => item.id));

        newSelectedIds.sort();
        loadedSelectedIds.sort();
        if (!equals(newSelectedIds, loadedSelectedIds)) {
            this.mediaSelectionStore.loadItems(newSelectedIds);
        }
    }

    componentWillUnmount() {
        this.changeDisposer();
    }

    @action openMediaOverlay() {
        this.overlayOpen = true;
    }

    @action closeMediaOverlay() {
        this.overlayOpen = false;
    }

    getLabel(itemCount) {
        if (itemCount && itemCount > 0) {
            return translate('sulu_media.select_more_media_plural');
        }

        return translate('sulu_media.select_media_plural');
    }

    handleRemove = (mediaId) => {
        this.mediaSelectionStore.removeById(mediaId);
    };

    handleSorted = (oldItemIndex, newItemIndex) => {
        this.mediaSelectionStore.move(oldItemIndex, newItemIndex);
    };

    handleOverlayOpen = () => {
        this.openMediaOverlay();
    };

    handleOverlayClose = () => {
        this.closeMediaOverlay();
    };

    handleOverlayConfirm = (selectedMedia) => {
        this.mediaSelectionStore.set([...this.mediaSelectionStore.items, ...selectedMedia]);
        this.closeMediaOverlay();
    };

    handleDisplayOptionClick = (displayOption) => {
        const { onChange, value } = this.props;

        onChange({ ...value, displayOption });
    };

    handleItemClick = (itemId, item) => {};

    render() {
        const { locale, disabled, displayOptions, sortable, types, value } = this.props;

        const { loading, items: medias } = this.mediaSelectionStore;
        const label = loading ? '' : this.getLabel(medias.length);

        const rightButton =
            displayOptions.length > 0
                ? {
                      icon: getIconForDisplayOption(value.displayOption),
                      onClick: this.handleDisplayOptionClick,
                      options: displayOptions.map((displayOption) => ({
                          icon: getIconForDisplayOption(displayOption),
                          label: getTranslationForDisplayOption(displayOption),
                          value: displayOption,
                      })),
                  }
                : undefined;

        return (
            <Fragment>
                <MultiItemSelection
                    disabled={!!disabled}
                    label={
                        <div style={{ cursor: 'pointer' }} onClick={!disabled ? this.handleOverlayOpen : undefined}>
                            {label}
                        </div>
                    }
                    leftButton={{
                        icon: 'su-image',
                        onClick: this.handleOverlayOpen,
                    }}
                    loading={loading}
                    onItemClick={this.handleItemClick}
                    onItemRemove={this.handleRemove}
                    onItemsSorted={this.handleSorted}
                    rightButton={rightButton}
                    sortable={sortable}
                >
                    {medias.map((media, index) => {
                        return (
                            <MultiItemSelection.Item id={media.id} index={index + 1} key={media.id} value={media}>
                                <div className={multiMediaSelectionStyle.mediaItem}>
                                    {media.thumbnails && media.thumbnails[THUMBNAIL_SIZE] ? (
                                        <img
                                            alt={media.title}
                                            className={multiMediaSelectionStyle.thumbnailImage}
                                            src={media.thumbnails[THUMBNAIL_SIZE]}
                                        />
                                    ) : (
                                        <MimeTypeIndicator
                                            height={25}
                                            iconSize={16}
                                            mimeType={media.mimeType}
                                            width={25}
                                        />
                                    )}
                                    <div className={multiMediaSelectionStyle.mediaTitle}>
                                        <CroppedText>{media.title}</CroppedText>
                                    </div>
                                </div>
                            </MultiItemSelection.Item>
                        );
                    })}
                </MultiItemSelection>
                <MultiMediaSelectionOverlay
                    excludedIds={medias.map((media) => media.id)}
                    locale={locale}
                    onClose={this.handleOverlayClose}
                    onConfirm={this.handleOverlayConfirm}
                    open={this.overlayOpen}
                    types={types}
                />
            </Fragment>
        );
    }
}

export default MultiMediaSelection;
