import React from 'react';
import InputComponent from 'sulu-admin-bundle/components/Input';
import routeLinkStyles from './routeLink.scss';

class RouteLink extends React.Component {
    handleBlur = () => {
        this.props.onFinish();
    };

    render() {
        const {
            dataPath,
            error,
            onChange,
            schemaOptions: {
                headline: { value: headline } = {},
                max_characters: { value: maxCharacters } = {},
                soft_max_length: { value: softMaxLength } = {},
                max_segments: { value: maxSegments } = {},
                segment_delimiter: { value: segmentDelimiter } = {},
            } = {},
            value,
        } = this.props;

        if (headline !== undefined && typeof headline !== 'boolean') {
            throw new Error('The "headline" schema option must be a boolean!');
        }

        if (maxCharacters !== undefined && isNaN(maxCharacters)) {
            throw new Error('The "max_characters" schema option must be a number!');
        }

        if (softMaxLength !== undefined && isNaN(softMaxLength)) {
            throw new Error('The "soft_max_length" schema option must be a number!');
        }

        const evaluatedSoftMaxLength = softMaxLength || maxCharacters;

        if (maxSegments !== undefined && isNaN(maxSegments)) {
            throw new Error('The "max_segments" schema option must be a number!');
        }

        if (segmentDelimiter !== undefined && typeof segmentDelimiter !== 'string') {
            throw new Error('The "segment_delimiter" schema option must be a string!');
        }

        if (this.props.data.wasPublished) {
            return (
                <div>
                    <a
                        href={this.props.disabled ? this.props.data.route + this.props.value : undefined}
                        style={{
                            color: !this.props.disabled && 'gray',
                        }}
                    >
                        {this.props.data.route + this.props.value}
                    </a>
                </div>
            );
        }
        const url = new URL(this.props.data.route);

        const pathSegments = url.pathname.split('/');

        const mainRoute = pathSegments.length > 2 ? `/${pathSegments[1]}/` : '/';

        return (
            <div className={routeLinkStyles.url}>
                <div>{mainRoute}</div>
                <InputComponent
                    disabled={this.props.data.canChange === false}
                    headline={headline}
                    id={dataPath}
                    maxCharacters={evaluatedSoftMaxLength ? parseInt(evaluatedSoftMaxLength) : undefined}
                    maxSegments={maxSegments ? parseInt(maxSegments) : undefined}
                    onBlur={this.handleBlur}
                    onChange={onChange}
                    segmentDelimiter={segmentDelimiter}
                    valid={!error}
                    value={value}
                />
            </div>
        );
    }
}

export default RouteLink;
