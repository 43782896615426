import React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import Popover from 'sulu-admin-bundle/components/Toolbar/Popover';
import OptionList from 'sulu-admin-bundle/components/Toolbar/OptionList';

@observer
class Select extends React.Component {
    static defaultProps = {
        showText: true,
    };

    @computed get selectedOption() {
        return this.props.options.find((option) => {
            return option.value === this.props.value;
        });
    }

    handleOptionClick = (option) => {
        this.props.onChange(option.value);
    };

    render() {
        const { className, disabled, icon, label, loading, options, showText, size, skin, value } = this.props;

        const buttonValue = this.selectedOption ? this.selectedOption.label : label;

        return (
            <Popover
                className={className}
                disabled={disabled}
                icon={icon}
                label={showText ? buttonValue : undefined}
                loading={loading}
                size={size}
                skin={skin}
            >
                {(onClose) => (
                    <OptionList
                        onClose={onClose}
                        onOptionClick={this.handleOptionClick}
                        options={options}
                        size={size}
                        skin={skin}
                        value={value}
                    />
                )}
            </Popover>
        );
    }
}

export default Select;
