import jexl from 'jexl';

import { translate } from 'sulu-admin-bundle/utils/Translator';
import { AbstractFormToolbarAction } from 'sulu-admin-bundle/views';
import { userStore } from 'sulu-admin-bundle/stores';

const USER_SETTING_PREFIX = 'sulu_page.webspace_tabs';
const USER_SETTING_WEBSPACE = [USER_SETTING_PREFIX, 'webspace'].join('.');

export default class CreateConceptToolbarAction extends AbstractFormToolbarAction {
    getToolbarItemConfig() {
        const { visible_condition: visibleCondition, options: submitOptions, label } = this.options;

        const { dirty, saving } = this.resourceFormStore;

        if (submitOptions && typeof submitOptions !== 'object') {
            throw new Error('The "options" option must be an object!');
        }

        const visibleConditionFulfilled = !visibleCondition || jexl.evalSync(visibleCondition, this.conditionData);
        const webspace = userStore.getPersistentSetting(USER_SETTING_WEBSPACE);

        if (visibleConditionFulfilled) {
            return {
                disabled: !dirty,
                icon: 'su-en-plus-circle',
                label: label ? translate(label) : translate('sulu_admin.create_draft'),
                loading: saving,
                onClick: () => {
                    this.form.submit({ ...submitOptions, webspace });
                },
                type: 'button',
            };
        }
    }
}
