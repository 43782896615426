import React from 'react';
import classNames from 'classnames';
import Button from 'sulu-admin-bundle/components/Toolbar/Button';
import Controls from 'sulu-admin-bundle/components/Toolbar/Controls';
import Dropdown from 'sulu-admin-bundle/components/Toolbar/Dropdown';
import Items from 'sulu-admin-bundle/components/Toolbar/Items';
import Icons from 'sulu-admin-bundle/components/Toolbar/Icons';
import Popover from 'sulu-admin-bundle/components/Toolbar/Popover';
import Toggler from 'sulu-admin-bundle/components/Toolbar/Toggler';
import Select from './Select';
import toolbarStyles from 'sulu-admin-bundle/components/Toolbar/toolbar.scss';

export default class ToolbarSelector extends React.PureComponent {
    static defaultProps = {
        skin: 'light',
    };

    static Button = Button;
    static Controls = Controls;
    static Dropdown = Dropdown;
    static Icons = Icons;
    static Items = Items;
    static Popover = Popover;
    static Select = Select;
    static Toggler = Toggler;

    static createChildren(children, skin) {
        return React.Children.map(children, (child) => {
            if (!child) {
                return null;
            }

            return React.cloneElement(child, {
                ...child.props,
                skin,
            });
        });
    }

    render() {
        const { children, skin } = this.props;

        const toolbarClass = classNames(toolbarStyles.toolbar, toolbarStyles[skin]);

        return <nav className={toolbarClass}>{ToolbarSelector.createChildren(children, skin)}</nav>;
    }
}
