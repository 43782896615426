import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import Block from './Block';
import { translate } from 'sulu-admin-bundle/utils';
import SortableHandle from 'sulu-admin-bundle/components/BlockCollection/SortableHandle';
import SelectionHandle from 'sulu-admin-bundle/components/BlockCollection/SelectionHandle';

@observer
class SortableBlock extends React.Component {
    static defaultProps = {
        actions: [],
        mode: 'sortable',
        movable: true,
        selected: false,
    };

    @computed get actions() {
        const { onRemove, actions, sortIndex } = this.props;

        const wrappedActions = actions.map((action) => {
            if (action.type !== 'divider') {
                return {
                    ...action,
                    onClick: () => action.onClick(sortIndex),
                };
            }

            return action;
        });

        if (onRemove) {
            return [
                ...wrappedActions,
                {
                    type: 'button',
                    icon: 'su-trash-alt',
                    label: translate('sulu_admin.delete'),
                    onClick: () => onRemove(sortIndex),
                },
            ];
        }

        return wrappedActions;
    }

    handleCollapse = () => {
        const { sortIndex, onCollapse } = this.props;

        if (onCollapse) {
            onCollapse(sortIndex);
        }
    };

    handleExpand = () => {
        const { sortIndex, onExpand } = this.props;

        if (onExpand) {
            onExpand(sortIndex);
        }
    };

    handleSelectionChanged = () => {
        const { sortIndex, onSelect, onUnselect, selected } = this.props;

        if (selected && onUnselect) {
            onUnselect(sortIndex);
        }

        if (!selected && onSelect) {
            onSelect(sortIndex);
        }
    };

    handleSettingsClick = () => {
        const { sortIndex, onSettingsClick } = this.props;

        if (onSettingsClick) {
            onSettingsClick(sortIndex);
        }
    };

    handleTypeChange = (type) => {
        const { sortIndex, onTypeChange } = this.props;

        if (onTypeChange) {
            onTypeChange(type, sortIndex);
        }
    };

    renderHandle = () => {
        const { mode, movable, selected } = this.props;

        if (mode === 'sortable' && movable !== false) {
            return <SortableHandle />;
        }

        if (mode === 'selectable') {
            return <SelectionHandle checked={selected} onChange={this.handleSelectionChanged} />;
        }

        return null;
    };

    render() {
        const {
            activeType,
            expanded,
            icons,
            onCollapse,
            onExpand,
            onSettingsClick,
            renderBlockContent,
            selected,
            sortIndex,
            types,
            value,
        } = this.props;

        return (
            <Block
                actions={this.actions}
                activeType={activeType}
                expanded={expanded}
                handle={this.renderHandle()}
                icons={icons}
                onCollapse={onCollapse ? this.handleCollapse : undefined}
                onExpand={onExpand ? this.handleExpand : undefined}
                onSettingsClick={onSettingsClick && this.handleSettingsClick}
                onTypeChange={this.handleTypeChange}
                selected={selected}
                types={types}
            >
                {renderBlockContent(value, activeType, sortIndex, expanded)}
            </Block>
        );
    }
}

const SortableElementBlock = SortableElement(SortableBlock);
export default SortableElementBlock;
