import React from 'react';
import classNames from 'classnames';
import Icon from 'sulu-admin-bundle/components/Icon/Icon';
import itemStyles from 'sulu-admin-bundle/components/Navigation/item.scss';

export default class Item extends React.PureComponent {
    handleClick = () => {
        const { onClick, value } = this.props;

        if (!onClick) {
            return;
        }

        onClick(value);
    };

    render() {
        const { title, children, expanded, child } = this.props;
        let { active } = this.props;

        if (children) {
            React.Children.forEach(children, (child) => {
                if (child.props.active) {
                    active = true;
                }
            });
        }

        const itemClass = classNames(itemStyles.item, {
            [itemStyles.active]: active,
        });

        return (
            <div className={itemClass}>
                <button
                    className={itemStyles.title}
                    onClick={this.handleClick}
                    type="button"
                    style={{
                        paddingLeft: child ? 32 : undefined,
                    }}
                >
                    <span className={itemStyles.text}>{title}</span>
                    {children && (
                        <Icon
                            className={itemStyles.childrenIndicator}
                            name={expanded ? 'su-angle-down' : 'su-angle-right'}
                        />
                    )}
                </button>

                {expanded && children && <div>{children}</div>}
            </div>
        );
    }
}
