import React from 'react';
import { Icon } from 'sulu-admin-bundle/components';
import buttonStyles from './button.scss';

class Button extends React.PureComponent {
    static defaultProps = {
        disabled: false,
    };

    handleClick = (event) => {
        const { onClick } = this.props;

        event.preventDefault();
        onClick();
    };

    render() {
        const { disabled, icon } = this.props;

        return (
            <button className={buttonStyles.button} disabled={disabled} onClick={this.handleClick} type="button">
                <Icon name={icon} />
            </button>
        );
    }
}

export default Button;
