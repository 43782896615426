import React from 'react';
import { observer } from 'mobx-react';
import { computed, isArrayLike } from 'mobx';
import NavigationComponent from './Navigation';
import userStore from 'sulu-admin-bundle/stores/userStore';
import navigationRegistry from 'sulu-admin-bundle/containers/Navigation/registries/navigationRegistry';

const SULU_CHANGELOG_URL = 'https://github.com/sulu/sulu/releases';

@observer
class NavigationSidebar extends React.Component {
    @computed get username() {
        if (!userStore.loggedIn || !userStore.contact) {
            return '';
        }

        return userStore.contact.fullName;
    }

    @computed get userImage() {
        if (!userStore.loggedIn || !userStore.contact || !userStore.contact.avatar) {
            return undefined;
        }

        return userStore.contact.avatar.thumbnails['sulu-50x50'];
    }

    handleNavigationItemClick = (value) => {
        const navigationItem = navigationRegistry.get(value);
        const view = navigationItem.view;

        if (!view) {
            return;
        }

        this.props.router.navigate(view);
        this.props.onNavigate(view);
    };

    handleProfileEditClick = () => {
        this.props.onProfileClick();
    };

    closeNavigation = () => {
        this.props.closeNavigation();
    };

    isNavigationItemActive = (navigationItem, routeName) => {
        return (
            (navigationItem.view && routeName === navigationItem.view) ||
            (navigationItem.childViews && navigationItem.childViews.includes(routeName))
        );
    };

    isItemActive = (navigationItem) => {
        const { router } = this.props;

        const routeName = router.route ? router.route.name : undefined;

        if (!routeName) {
            console.warn('No route found in router');
            return false;
        }

        if (navigationItem.view) {
            switch (routeName) {
                case 'article_edit_form.details':
                case 'article_edit_form':
                case 'article_edit_form.details_seo':
                case 'article_edit_form.activity':
                    return this.isNavigationItemActive(navigationItem, 'article_list');
                case 'article_tag_edit_form.details':
                case 'article_tag_edit_form':
                    return this.isNavigationItemActive(navigationItem, 'article_tag_list');
                case 'career_edit_form':
                case 'career_edit_form.details':
                case 'career_edit_form.details_seo':
                case 'career_edit_form.activity':
                    return this.isNavigationItemActive(navigationItem, 'career_list');
                case 'employee_edit_form':
                case 'employee_edit_form.details':
                    return this.isNavigationItemActive(navigationItem, 'employee_list');
                case 'department_edit_form':
                case 'department_edit_form.details':
                    return this.isNavigationItemActive(navigationItem, 'department_list');
                case 'investment_edit_form':
                case 'investment_edit_form.details':
                case 'investment_edit_form.details_seo':
                case 'investment_edit_form.activity':
                    return this.isNavigationItemActive(navigationItem, 'investment_list');
                case 'investment_tag_edit_form':
                case 'investment_tag_edit_form.details':
                    return this.isNavigationItemActive(navigationItem, 'investment_tag_list');
                case 'project_edit_form':
                case 'project_edit_form.details':
                case 'project_edit_form.details_seo':
                case 'project_edit_form.activity':
                    return this.isNavigationItemActive(navigationItem, 'project_list');
                case 'project_tag_edit_form':
                case 'project_tag_edit_form.details':
                    return this.isNavigationItemActive(navigationItem, 'project_tag_list');
                default:
                    break;
            }
        }

        return this.isNavigationItemActive(navigationItem, routeName);
    };

    render() {
        const { appVersion, suluVersion } = this.props;
        const navigationItems = navigationRegistry.getAll();

        return (
            <NavigationComponent
                appVersion={appVersion}
                onItemClick={this.handleNavigationItemClick}
                onLogoutClick={this.props.onLogout}
                onProfileClick={this.handleProfileEditClick}
                closeNavigation={this.closeNavigation}
                pinned={this.props.pinned}
                suluVersion={suluVersion}
                suluVersionLink={SULU_CHANGELOG_URL}
                title="Energon"
                userImage={this.userImage}
                username={this.username}
            >
                {navigationItems
                    .filter((item) => item.visible)
                    .map((item) => (
                        <NavigationComponent.Item
                            active={this.isItemActive(item)}
                            icon={undefined}
                            key={item.id}
                            title={item.label}
                            value={item.id}
                        >
                            {isArrayLike(item.items) &&
                                item.items
                                    .filter((subItem) => subItem.visible)
                                    .map((subItem) => (
                                        <NavigationComponent.Item
                                            active={this.isItemActive(subItem)}
                                            key={subItem.id}
                                            title={subItem.label}
                                            value={subItem.id}
                                            child
                                        />
                                    ))}
                        </NavigationComponent.Item>
                    ))}
            </NavigationComponent>
        );
    }
}

export default NavigationSidebar;
