import { action, computed, observable } from 'mobx';
import ResourceRequester from 'sulu-admin-bundle/services/ResourceRequester';
import { AbstractFormToolbarAction } from 'sulu-admin-bundle/views';
import { translate } from 'sulu-admin-bundle/utils/Translator';
import snackbarStore from 'sulu-admin-bundle/stores/snackbarStore';

export default class PublishToolbarAction extends AbstractFormToolbarAction {
    @observable loading = false;

    @computed get property() {
        const { property } = this.options;

        if (typeof property !== 'string') {
            throw new Error('The "property" option must be a string value!');
        }

        return property;
    }

    @computed get label() {
        const { label } = this.options;

        if (typeof label !== 'string') {
            throw new Error('The "label" option must be a string value!');
        }

        return label;
    }

    @computed get activateAction() {
        const { activate } = this.options;

        if (typeof activate !== 'string') {
            throw new Error('The "activate" option must be a string value!');
        }

        return activate;
    }

    @computed get deactivateAction() {
        const { deactivate } = this.options;

        if (typeof deactivate !== 'string') {
            throw new Error('The "deactivate" option must be a string value!');
        }

        return deactivate;
    }

    @computed get isActive() {
        return this.resourceFormStore.data[this.property];
    }

    getToolbarItemConfig() {
        if (!this.resourceFormStore.data.canChange) {
            return;
        }

        const disabled =
            this.resourceFormStore.loading || this.resourceFormStore.dirty || !this.resourceFormStore.data.id;

        return {
            type: 'toggler',
            onClick: action(() => {
                if (disabled) return;

                this.handleTogglerClick();
            }),
            label: translate(this.label),
            loading: this.loading,
            disabled,
            value: this.isActive,
        };
    }

    @action handleTogglerClick = () => {
        const {
            resourceKey,
            locale,
            data: { id },
        } = this.resourceFormStore;

        this.loading = true;
        ResourceRequester.post(resourceKey, undefined, {
            action: this.isActive ? this.deactivateAction : this.activateAction,
            locale,
            id,
        })
            .then(
                action((response) => {
                    this.resourceFormStore.change(this.property, response[this.property], { isServerValue: true });
                    this.loading = false;
                    let successMessage;

                    if (this.isActive) {
                        switch (resourceKey) {
                            case 'careers':
                                successMessage = translate('careers_published');
                                break;
                            case 'articles':
                                successMessage = translate('articles_published');
                                break;
                            case 'investment':
                                successMessage = translate('investment_published');
                                break;
                            case 'projects':
                                successMessage = translate('projects_published');
                                break;
                            case 'employees':
                                successMessage = translate('employees_published');
                                break;

                            default:
                                break;
                        }
                    }

                    if (successMessage) {
                        snackbarStore.add(
                            {
                                type: 'success',
                                text: successMessage,
                            },
                            2500,
                        );
                    } else {
                        this.form.showSuccessSnackbar();
                    }
                }),
            )
            .catch(
                action((response) => {
                    response.json().then(
                        action((error) => {
                            this.form.errors.push(error.detail || error.title || translate('sulu_trash.restore_error'));
                            this.loading = false;
                        }),
                    );
                }),
            );
    };
}
