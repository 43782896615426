/* eslint-disable no-case-declarations */
import { observer } from 'mobx-react';
import { action, computed, toJS } from 'mobx';
import React, { Fragment } from 'react';
import ToolbarComponent from './ToolbarSelector';
import toolbarStorePool, { DEFAULT_STORE_KEY } from 'sulu-admin-bundle/containers/Toolbar/stores/toolbarStorePool';
import toolbarStyles from 'sulu-admin-bundle/containers/Toolbar/toolbar.scss';
import { translate } from 'sulu-admin-bundle/utils';
import snackbarStore from 'sulu-admin-bundle/stores/snackbarStore';
import Snackbar from '../../components/Snackbar';

const ToolbarItemTypes = {
    Button: 'button',
    Dropdown: 'dropdown',
    Select: 'select',
    Toggler: 'toggler',
};

const getItemComponentByType = (itemConfig, key) => {
    switch (itemConfig.type) {
        case ToolbarItemTypes.Select:
            const { type: selectType, ...selectConfig } = itemConfig;
            return <ToolbarComponent.Select {...selectConfig} key={key} />;
        case ToolbarItemTypes.Dropdown:
            const { type: dropdownType, ...dropdownConfig } = itemConfig;
            return <ToolbarComponent.Dropdown {...dropdownConfig} key={key} />;
        case ToolbarItemTypes.Toggler:
            const { type: togglerType, ...togglerConfig } = itemConfig;
            return <ToolbarComponent.Toggler {...togglerConfig} key={key} />;
        default:
            const { type: buttonType, ...buttonConfig } = itemConfig;
            return <ToolbarComponent.Button {...buttonConfig} key={key} />;
    }
};

class SnackbarSender extends React.Component {
    componentDidMount() {
        snackbarStore.add(
            {
                text: this.props.message,
                type: 'success',
            },
            2500,
        );
    }

    render() {
        return null;
    }
}

@observer
class Toolbar extends React.Component {
    static defaultProps = {
        navigationOpen: false,
    };

    toolbarStore;

    constructor(props) {
        super(props);

        this.setStore(this.props.storeKey);
    }

    componentDidUpdate(nextProps) {
        if (nextProps.storeKey) {
            this.setStore(nextProps.storeKey);
        }
    }

    setStore = (storeKey = DEFAULT_STORE_KEY) => {
        if (toolbarStorePool.hasStore(storeKey)) {
            this.toolbarStore = toolbarStorePool.getStore(storeKey);
        } else {
            this.toolbarStore = toolbarStorePool.createStore(storeKey);
        }
    };

    @action handleErrorSnackbarCloseClick = () => {
        this.toolbarStore.errors.pop();
    };

    @computed get disableAllButtons() {
        const loadingItems = this.toolbarStore.getItemsConfig().filter((item) => item.loading);
        return this.toolbarStore.disableAll || loadingItems.length > 0;
    }

    createSuccessMessage = () => {
        const { router } = this.props;
        const routeName = router.route?.name;

        switch (routeName) {
            case 'article_add_form.details':
                return translate('article_successfully_created');
            case 'article_edit_form.details':
            case 'article_edit_form':
            case 'article_edit_form.details_seo':
            case 'article_edit_form.activity':
                return translate('article_successfully_saved');
            case 'article_tag_add_form.details':
                return translate('article_tag_successfully_created');
            case 'article_tag_edit_form.details':
            case 'article_tag_edit_form':
                return translate('article_tag_successfully_saved');
            case 'career_add_form.details':
                return translate('career_successfully_created');
            case 'career_edit_form':
            case 'career_edit_form.details':
            case 'career_edit_form.details_seo':
            case 'career_edit_form.activity':
                return translate('career_successfully_saved');
            case 'employee_add_form.details':
                return translate('employee_successfully_created');
            case 'employee_edit_form':
            case 'employee_edit_form.details':
                return translate('employee_successfully_saved');
            case 'department_add_form.details':
                return translate('department_successfully_created');
            case 'department_edit_form':
            case 'department_edit_form.details':
                return translate('department_successfully_saved');
            case 'investment_add_form.details':
                return translate('investment_successfully_created');
            case 'investment_edit_form':
            case 'investment_edit_form.details':
            case 'investment_edit_form.details_seo':
            case 'investment_edit_form.activity':
                return translate('investment_successfully_saved');
            case 'investment_tag_add_form.details':
                return translate('investment_tag_successfully_created');
            case 'investment_tag_edit_form':
            case 'investment_tag_edit_form.details':
                return translate('investment_tag_successfully_saved');
            case 'project_add_form.details':
                return translate('project_successfully_created');
            case 'project_edit_form':
            case 'project_edit_form.details':
            case 'project_edit_form.details_seo':
            case 'project_edit_form.activity':
                return translate('project_successfully_saved');
            case 'project_tag_add_form.details':
                return translate('project_tag_successfully_created');
            case 'project_tag_edit_form':
            case 'project_tag_edit_form.details':
                return translate('project_tag_successfully_saved');
            case 'energon_user_add_form.details':
                return translate('energon_user_successfully_created');
            case 'energon_user_edit_form':
            case 'energon_user_edit_form.details':
                return translate('energon_user_successfully_saved');
            default:
                return undefined;
        }
    };

    createButtonConfig = () => {
        const { router } = this.props;
        const routeName = router.route?.name;

        switch (routeName) {
            case 'article_add_form.details':
            case 'article_edit_form.details':
            case 'article_edit_form':
            case 'article_edit_form.details_seo':
            case 'article_edit_form.activity':
                return {
                    label: translate('article_list'),
                    showText: true,
                    onClick: () => {
                        router.navigate('article_list', router.attributes);
                    },
                };
            case 'article_tag_add_form.details':
            case 'article_tag_edit_form.details':
            case 'article_tag_edit_form':
                return {
                    label: translate('article_tag_list'),
                    showText: true,
                    onClick: () => {
                        router.navigate('article_tag_list', router.attributes);
                    },
                };
            case 'career_add_form.details':
            case 'career_edit_form':
            case 'career_edit_form.details':
            case 'career_edit_form.details_seo':
            case 'career_edit_form.activity':
                return {
                    label: translate('career_list'),
                    showText: true,
                    onClick: () => {
                        router.navigate('career_list', router.attributes);
                    },
                };
            case 'employee_add_form.details':
            case 'employee_edit_form':
            case 'employee_edit_form.details':
                return {
                    label: translate('employee_list'),
                    showText: true,
                    onClick: () => {
                        router.navigate('employee_list', router.attributes);
                    },
                };
            case 'department_add_form.details':
            case 'department_edit_form':
            case 'department_edit_form.details':
                return {
                    label: translate('department_list'),
                    showText: true,
                    onClick: () => {
                        router.navigate('department_list', router.attributes);
                    },
                };
            case 'investment_add_form.details':
            case 'investment_edit_form':
            case 'investment_edit_form.details':
            case 'investment_edit_form.details_seo':
            case 'investment_edit_form.activity':
                return {
                    label: translate('investment_list'),
                    showText: true,
                    onClick: () => {
                        router.navigate('investment_list', router.attributes);
                    },
                };
            case 'investment_tag_add_form.details':
            case 'investment_tag_edit_form':
            case 'investment_tag_edit_form.details':
                return {
                    label: translate('investment_tag_list'),
                    showText: true,
                    onClick: () => {
                        router.navigate('investment_tag_list', router.attributes);
                    },
                };
            case 'project_add_form.details':
            case 'project_edit_form':
            case 'project_edit_form.details':
            case 'project_edit_form.details_seo':
            case 'project_edit_form.activity':
                return {
                    label: translate('project_list'),
                    showText: true,
                    onClick: () => {
                        router.navigate('project_list', router.attributes);
                    },
                };
            case 'project_tag_add_form.details':
            case 'project_tag_edit_form':
            case 'project_tag_edit_form.details':
                return {
                    label: translate('project_tag_list'),
                    showText: true,
                    onClick: () => {
                        router.navigate('project_tag_list', router.attributes);
                    },
                };
            case 'energon_user_add_form.details':
            case 'energon_user_edit_form':
            case 'energon_user_edit_form.details':
                return {
                    label: translate('energon_user_list'),
                    showText: true,
                    onClick: () => {
                        router.navigate('energon_user_list', router.attributes);
                    },
                };
            default:
                return undefined;
        }
    };

    @computed get backButtonConfig() {
        const backButtonConfig = this.toolbarStore.getBackButtonConfig();

        if (!backButtonConfig) {
            return undefined;
        }

        const config = this.createButtonConfig() || backButtonConfig;

        if (this.disableAllButtons) {
            config.disabled = true;
        }

        return config;
    }

    @computed get itemsConfig() {
        const itemsConfig = this.toolbarStore.getItemsConfig();

        if (this.disableAllButtons) {
            itemsConfig.forEach((item) => {
                item.disabled = true;
            });
        }

        return itemsConfig;
    }

    handleLocaleChange = (locale) => {
        const { router } = this.props;
        const route = router.route;

        const options = { ...router.attributes, locale };
        router.navigate(route.name, options);
    };

    handleOpenPreview = () => {
        window.handlePreviewWindowClick();
    };

    render() {
        const { onNavigationButtonClick, navigationOpen } = this.props;
        const { errors, showSuccess, warnings } = this.toolbarStore;

        const iconsConfig = this.toolbarStore.getIconsConfig();
        const itemsConfig = this.toolbarStore.getItemsConfig();

        const localeConfig = window.location.href.includes('/collections/')
            ? null
            : this.toolbarStore.getLocaleConfig();

        return (
            <Fragment>
                <Snackbar
                    message={errors[errors.length - 1]}
                    onCloseClick={this.handleErrorSnackbarCloseClick}
                    type="error"
                    visible={errors.length > 0}
                />
                <Snackbar message={warnings[warnings.length - 1]} type="warning" visible={warnings.length > 0} />
                <ToolbarComponent>
                    <ToolbarComponent.Controls grow={true}>
                        {!!onNavigationButtonClick && !navigationOpen && (
                            <ToolbarComponent.Button
                                disabled={!onNavigationButtonClick}
                                icon={'su-bars'}
                                onClick={onNavigationButtonClick}
                                primary={true}
                                success={false}
                            />
                        )}
                        {!!this.backButtonConfig && (
                            <ToolbarComponent.Button
                                {...this.backButtonConfig}
                                icon={'su-angle-left'}
                                success={false}
                                label={this.backButtonConfig.label}
                            />
                        )}

                        {showSuccess && <SnackbarSender message={this.createSuccessMessage()} />}

                        {(itemsConfig.length > 0 || this.props.shouldShowPreviewButton) && (
                            <ToolbarComponent.Items>
                                {this.itemsConfig
                                    .reverse()
                                    .map((itemConfig, index) => getItemComponentByType(itemConfig, index))}
                                {this.props.shouldShowPreviewButton && (
                                    <ToolbarComponent.Button onClick={this.handleOpenPreview} icon="su-desktop">
                                        {translate('sulu_admin.preview')}
                                    </ToolbarComponent.Button>
                                )}
                            </ToolbarComponent.Items>
                        )}
                    </ToolbarComponent.Controls>
                    <ToolbarComponent.Controls>
                        {iconsConfig.length > 0 && (
                            <ToolbarComponent.Icons>
                                {iconsConfig.map((icon) => {
                                    return toJS(icon);
                                })}
                            </ToolbarComponent.Icons>
                        )}
                        {!!localeConfig && (
                            <ToolbarComponent.Select
                                className={toolbarStyles.locale}
                                {...localeConfig}
                                options={[
                                    {
                                        label: translate('sulu_admin.english_version'),
                                        value: 'en',
                                        disabled: true,
                                    },
                                    {
                                        label: translate('sulu_admin.czech_version'),
                                        value: 'cs',
                                    },
                                ]}
                                onChange={this.handleLocaleChange}
                            />
                        )}
                    </ToolbarComponent.Controls>
                </ToolbarComponent>
            </Fragment>
        );
    }
}

export default Toolbar;
