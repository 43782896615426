import validateDisplayOption from './validateDisplayOption';

export default function convertDisplayOptionsFromParams(displayOptions) {
    if (!displayOptions) {
        return [];
    }

    return displayOptions
        .filter((displayOption) => displayOption.value === true)
        .map(({ name }) => {
            if (!validateDisplayOption(name)) {
                throw new Error('The children of "displayOptions" contains the invalid value "' + (name || '') + '".');
            }
            return name;
        });
}
