import React from 'react';
import { comparer, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import MediaSelectionOverlay from '../MediaSelectionOverlay';

@observer
class MultiMediaSelectionOverlay extends React.Component {
    static defaultProps = {
        confirmLoading: false,
        excludedIds: [],
        types: [],
    };

    collectionId = observable.box();
    mediaListStore;
    collectionListStore;
    excludedIdsDisposer;

    constructor(props) {
        super(props);

        const excludedIds = computed(() => (this.props.excludedIds.length ? this.props.excludedIds : undefined), {
            equals: comparer.structural,
        });
        this.excludedIdsDisposer = excludedIds.observe(() => this.mediaListStore.clear());

        this.mediaListStore = MediaSelectionOverlay.createMediaListStore(
            this.collectionId,
            excludedIds,
            props.locale,
            props.types,
        );
        this.collectionListStore = MediaSelectionOverlay.createCollectionListStore(this.collectionId, props.locale);
    }

    componentWillUnmount() {
        this.mediaListStore.destroy();
        this.collectionListStore.destroy();
        this.excludedIdsDisposer();
    }

    render() {
        const { confirmLoading, onClose, onConfirm, open, locale } = this.props;

        return (
            <MediaSelectionOverlay
                collectionId={this.collectionId}
                collectionListStore={this.collectionListStore}
                confirmLoading={confirmLoading}
                locale={locale}
                mediaListStore={this.mediaListStore}
                onClose={onClose}
                onConfirm={onConfirm}
                open={open}
            />
        );
    }
}

export default MultiMediaSelectionOverlay;
