import { initializer, Config } from 'sulu-admin-bundle/services';
import {
    sidebarRegistry,
    ckeditorPluginRegistry,
    ckeditorConfigRegistry,
    viewRegistry,
    conditionDataProviderRegistry,
    fieldRegistry,
    ResourceLocator,
} from 'sulu-admin-bundle/containers';
import Preview from './src/containers/preview/Preview';
import PreviewStore from './src/containers/preview/stores/PreviewStore';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { formToolbarActionRegistry, listItemActionRegistry } from 'sulu-admin-bundle/views';
import ResetPasswordToolbarAction from './src/containers/toolbar/ResetPasswordToolbarAction';
import PublishToolbarAction from './src/containers/toolbar/PublishToolbarAction';
import PublishWithDialogToolbarAction from './src/containers/toolbar/PublishWithDialogToolbarAction';
import SaveToolbarAction from './src/containers/toolbar/SaveToolbarAction';
import DeleteToolbarAction from './src/containers/toolbar/DeleteToolbarAction';
import listAdapterRegistry from 'sulu-admin-bundle/containers/List/registries/listAdapterRegistry';
import TableAdapter from './src/adapters/TableAdapter';
import ArticleTagAutoComplete from './src/fields/ArticleTagAutoComplete';
import AdditionalWebspaceSelect from './src/fields/AdditionalWebspaceSelect';
import DepartmentAutoComplete from './src/fields/DepartmentAutoComplete';
import RouteLink from './src/fields/RouteLink';
import MainPage from './src/containers/main/MainPage';
import webspaceConditionDataProvider from 'sulu-page-bundle/containers/Form/conditionDataProviders/webspaceConditionDataProvider';
import SearchResult from 'sulu-page-bundle/containers/Form/fields/SearchResult';
import SegmentSelect from 'sulu-page-bundle/containers/Form/fields/SegmentSelect';
import TeaserSelection from 'sulu-page-bundle/containers/Form/fields/TeaserSelection';
import { teaserProviderRegistry } from 'sulu-page-bundle/containers/TeaserSelection';
import PageSettingsNavigationSelect from 'sulu-page-bundle/containers/Form/fields/PageSettingsNavigationSelect';
import PageSettingsShadowLocaleSelect from 'sulu-page-bundle/containers/Form/fields/PageSettingsShadowLocaleSelect';
import SettingsVersions from 'sulu-page-bundle/containers/Form/fields/SettingsVersions';
import webspaceStore from 'sulu-page-bundle/stores/webspaceStore';
import { loadResourceLocatorInputTypeByWebspace } from 'sulu-page-bundle/utils/Webspace';
import PageTabs from 'sulu-page-bundle/views/PageTabs';
import PageList from 'sulu-page-bundle/views/PageList';
import WebspaceTabs from './src/containers/tabs/WebspaceTabs';
import RestoreVersionItemAction from 'sulu-page-bundle/views/List/itemActions/RestoreVersionItemAction';
import ProjectTagAutoComplete from './src/fields/ProjectTagAutoComplete';
import InvestmentTagAutoComplete from './src/fields/InvestmentTagAutoComplete';
import CreateConceptToolbarAction from './src/containers/toolbar/CreateConceptToolbarAction';
import MediaTableAdapter from './src/adapters/MediaTableAdapter';

initializer.addUpdateConfigHook('sulu_preview', (config) => {
    PreviewStore.endpoints = config.endpoints;
    Preview.debounceDelay = config.debounceDelay;
    Preview.mode = config.mode;
    Preview.audienceTargeting = config.audienceTargeting;

    if (config.mode === 'off') {
        sidebarRegistry.disable('sulu_preview.preview');
    }
});

initializer.addUpdateConfigHook('sulu_page', (config, initialized) => {
    webspaceStore.setWebspaces(Object.values(config.webspaces));

    if (initialized) {
        return;
    }

    viewRegistry.add('sulu_page.page_tabs', PageTabs, { disableDefaultSpacing: true });
    viewRegistry.add('sulu_page.page_list', PageList);
    viewRegistry.add('sulu_page.webspace_tabs', WebspaceTabs, { disableDefaultSpacing: true });

    fieldRegistry.add('page_settings_navigation_select', PageSettingsNavigationSelect);
    fieldRegistry.add('page_settings_shadow_locale_select', PageSettingsShadowLocaleSelect);
    fieldRegistry.add('search_result', SearchResult);
    fieldRegistry.add('segment_select', SegmentSelect);
    fieldRegistry.add('teaser_selection', TeaserSelection);

    conditionDataProviderRegistry.add(webspaceConditionDataProvider);

    fieldRegistry.add('resource_locator', ResourceLocator, {
        modeResolver: (props) => loadResourceLocatorInputTypeByWebspace(props.formInspector.options.webspace),
        generationUrl: Config.endpoints.generateUrl,
        historyResourceKey: 'page_resourcelocators',
        resourceStorePropertiesToRequest: {
            parentUuid: 'parentId',
        },
    });

    listItemActionRegistry.add('restore_version', RestoreVersionItemAction);

    if (config.versioning) {
        fieldRegistry.add('settings_versions', SettingsVersions);
    }

    for (const teaserProviderKey in config.teaser) {
        teaserProviderRegistry.add(teaserProviderKey, config.teaser[teaserProviderKey]);
    }
});

sidebarRegistry.add('sulu_preview.preview', Preview);

ckeditorPluginRegistry.add(BlockQuote);
ckeditorPluginRegistry.add(BlockQuote);
ckeditorConfigRegistry.add((config) => {
    if (Array.isArray(config.toolbar)) {
        config.toolbar = config.toolbar.filter((item) => item !== 'code' && item !== 'internalLink');
    }

    return {
        ...config,
        toolbar: [...config.toolbar, 'blockQuote'],
    };
});

formToolbarActionRegistry.add('sulu.reset_password', ResetPasswordToolbarAction);
formToolbarActionRegistry.add('publish-content', PublishToolbarAction);
formToolbarActionRegistry.add('publish-with-dialog', PublishWithDialogToolbarAction);
formToolbarActionRegistry.add('save_content', SaveToolbarAction);
formToolbarActionRegistry.add('create-concept', CreateConceptToolbarAction);
formToolbarActionRegistry.add('sulu.delete', DeleteToolbarAction);

listAdapterRegistry.add('common-table', TableAdapter);
listAdapterRegistry.add('media-table', MediaTableAdapter);

fieldRegistry.add('route_link', RouteLink);
fieldRegistry.add('article_tag_autocomplete', ArticleTagAutoComplete);
fieldRegistry.add('project_tag_autocomplete', ProjectTagAutoComplete);
fieldRegistry.add('investment_tag_autocomplete', InvestmentTagAutoComplete);
fieldRegistry.add('additional_webspace_select', AdditionalWebspaceSelect);
fieldRegistry.add('department_select', DepartmentAutoComplete);

viewRegistry.add('sulu_search.search', MainPage);
