import React from 'react';
import { computed, isArrayLike } from 'mobx';
import MultiSelectComponent from 'sulu-admin-bundle/components/MultiSelect';

export default class AdditionalWebspaceSelect extends React.Component {
    constructor(props) {
        super(props);

        const { onChange, schemaOptions, value } = this.props;

        const { default_values: { value: defaultOptions } = {} } = schemaOptions;

        if (defaultOptions === undefined || defaultOptions === null) {
            return;
        }

        if (!isArrayLike(defaultOptions)) {
            throw new Error('The "default_values" schema option must be an array!');
        }

        const defaultValues = defaultOptions.map(({ name: defaultValue }) => {
            if (typeof defaultValue !== 'number' && typeof defaultValue !== 'string') {
                throw new Error('A single schema option of "default_values" must be a string or number');
            }

            return defaultValue;
        });

        if (value === undefined) {
            onChange(defaultValues, { isDefaultValue: true });
        }
    }

    @computed get values() {
        const {
            data: { mainWebspace },
        } = this.props;
        const { values } = this.props.schemaOptions;
        return values.value.filter((value) => value.name !== mainWebspace);
    }

    handleChange = (value) => {
        const { onChange, onFinish } = this.props;

        const allowedValues = this.values.map((value) => value.name);
        const filteredValue = value.filter((v) => allowedValues.includes(v));

        onChange(filteredValue.length > 0 ? filteredValue : undefined);
        onFinish();
    };

    isDisabled = (value, disabled, locale) => {
        if (locale === 'en' && value !== 'energon_holding' && value !== 'energon_energy_solutions') {
            return true;
        }

        return disabled;
    };

    render() {
        const { disabled, value, data } = this.props;

        return (
            <MultiSelectComponent
                disabled={!!disabled}
                onChange={this.handleChange}
                values={value || []}
                allSelectedText={value ? this.values.map((v) => v.title).join(', ') : ''}
            >
                {this.values.map(({ name: value, title, disabled }) => {
                    return (
                        <MultiSelectComponent.Option
                            key={value}
                            value={value}
                            disabled={this.isDisabled(value, disabled, data.locale)}
                        >
                            {title}
                        </MultiSelectComponent.Option>
                    );
                })}
            </MultiSelectComponent>
        );
    }
}
