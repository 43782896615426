import React from 'react';
import { action, computed, observable, reaction, toJS, when } from 'mobx';
import { observer } from 'mobx-react';
import { sidebarStore } from 'sulu-admin-bundle/containers';
import { ResourceListStore } from 'sulu-admin-bundle/stores';
import { translate } from 'sulu-admin-bundle/utils';
import { webspaceStore } from 'sulu-page-bundle/stores';
import './public-preview.scss';
import PreviewStore from './stores/PreviewStore';

@observer
class Preview extends React.Component {
    static debounceDelay = 250;
    static mode = 'auto';
    static audienceTargeting = false;

    availableDeviceOptions = [
        { label: translate('sulu_preview.auto'), value: 'auto' },
        { label: translate('sulu_preview.desktop'), value: 'desktop' },
        { label: translate('sulu_preview.tablet'), value: 'tablet' },
        { label: translate('sulu_preview.smartphone'), value: 'smartphone' },
    ];

    @observable iframeRef;
    @observable started = false;
    @observable selectedDeviceOption = this.availableDeviceOptions[0].value;
    @observable targetGroupsStore;

    @observable previewStore;
    @observable previewWindow;
    @observable webspaceOptions = [];
    @observable reloadCounter = 0;

    schemaDisposer;
    dataDisposer;
    localeDisposer;

    @computed get webspaceKey() {
        const {
            router: {
                attributes: { webspace },
            },
        } = this.props;

        if (webspace !== undefined && typeof webspace !== 'string') {
            throw new Error('The "webspace" router attribute must be a string if set!');
        }

        return webspace || this.webspaceOptions[0].value;
    }

    @computed get segments() {
        if (!this.webspaceKey) {
            return [];
        }

        return webspaceStore.getWebspace(this.webspaceKey).segments;
    }

    @computed get shouldUpdateFormStore() {
        return this.props.formStore.resourceKey === this.previewStore.resourceKey;
    }

    constructor(props) {
        super(props);
        this.state = {
            shouldShowPreview: false,
        };

        if (Preview.audienceTargeting) {
            this.targetGroupsStore = new ResourceListStore('target_groups');
        }

        this.webspaceOptions = webspaceStore.grantedWebspaces.map((webspace) => ({
            label: webspace.name,
            value: webspace.key,
        }));

        this.createPreviewStore();
        if (Preview.mode === 'auto') {
            this.startPreview();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.formStore !== prevProps.formStore) {
            this.disposeFormStoreReactions();

            this.initializeFormStoreReactions();
        }
    }

    componentDidMount() {
        window.handleRefreshPreview = this.handleRefreshClick;
        window.handlePreviewWindowClick = this.handlePreviewWindowClick;
    }

    @action createPreviewStore = () => {
        const {
            formStore: { resourceKey, id, locale },
            router: {
                route: {
                    options: { previewResourceKey = null },
                },
            },
        } = this.props;

        this.previewStore = new PreviewStore(
            previewResourceKey || resourceKey,
            id,
            locale,
            this.webspaceKey,
            this.segments.find((segment) => segment.default === true)?.key,
        );
    };

    @action setStarted = (started) => {
        this.started = started;
    };

    startPreview = () => {
        const { previewStore } = this;

        const { formStore } = this.props;

        previewStore.start();

        when(
            () =>
                !formStore.loading &&
                !previewStore.starting &&
                this.iframeRef !== null &&
                (!this.targetGroupsStore || !this.targetGroupsStore.loading),
            this.initializeFormStoreReactions,
        );

        this.setStarted(true);
    };

    initializeFormStoreReactions = () => {
        const { previewStore } = this;

        const { formStore } = this.props;

        this.localeDisposer = reaction(
            () => toJS(formStore.locale),
            (locale) => {
                this.previewStore.restart(locale);
            },
        );

        if (previewStore.resourceKey !== formStore.resourceKey) {
            return;
        }

        this.schemaDisposer = reaction(
            () => toJS(formStore.schema),
            () => {
                if (formStore.type) {
                    previewStore.updateContext(toJS(formStore.type), toJS(formStore.data)).then(this.setContent);
                }
            },
        );
    };

    setContent = (previewContent) => {
        const previewDocument = this.getPreviewDocument();
        if (!previewDocument) {
            return;
        }

        const preservedScrollPosition = this.getPreviewScrollPosition();
        previewDocument.open();
        previewDocument.write(previewContent);
        previewDocument.close();

        if (preservedScrollPosition) {
            setTimeout(() => this.setPreviewScrollPosition(preservedScrollPosition), 0);
        }
    };

    componentWillUnmount() {
        this.disposeFormStoreReactions();

        if (!this.started) {
            return;
        }
        window.handleRefreshPreview = this.handleRefreshClick;

        this.previewStore.stop();

        if (this.previewWindow) {
            this.previewWindow.close();
        }

        delete window.handleRefreshPreview;
        delete window.handlePreviewWindowClick;
    }

    disposeFormStoreReactions() {
        if (this.schemaDisposer) {
            this.schemaDisposer();
        }

        if (this.dataDisposer) {
            this.dataDisposer();
        }

        if (this.localeDisposer) {
            this.localeDisposer();
        }
    }

    getPreviewDocument = () => {
        if (this.previewWindow) {
            return this.previewWindow.document;
        }

        if (!(this.iframeRef instanceof HTMLIFrameElement)) {
            return;
        }

        return this.iframeRef.contentDocument;
    };

    getPreviewWindow = () => {
        if (this.previewWindow) {
            return this.previewWindow;
        }

        if (!(this.iframeRef instanceof HTMLIFrameElement)) {
            return;
        }

        return this.iframeRef.contentWindow;
    };

    getPreviewScrollPosition = () => {
        const previewWindow = this.getPreviewWindow();
        if (previewWindow) {
            return (
                previewWindow.document?.documentElement?.scrollTop ||
                previewWindow.pageYOffset ||
                previewWindow.document?.body?.scrollTop
            );
        }
    };

    setPreviewScrollPosition = (pos) => {
        const previewWindow = this.getPreviewWindow();
        if (previewWindow) {
            previewWindow.scrollTo({ top: pos });
        }
    };

    @action setIframe = (iframeRef) => {
        this.iframeRef = iframeRef;
    };

    handleToggleSidebarClick = () => {
        sidebarStore.setSize('hidden-preview');
    };

    @action handleDeviceSelectChange = (value) => {
        this.selectedDeviceOption = value;
    };

    @action handleRefreshClick = () => {
        ++this.reloadCounter;

        if (this.previewWindow) {
            const iframe = this.previewWindow.document.querySelector('iframe');
            if (iframe) {
                // eslint-disable-next-line no-self-assign
                iframe.src = iframe.src;
            }
        }
    };

    handleStartClick = () => {
        this.startPreview();
    };

    @action handlePreviewWindowClick = () => {
        if (this.previewWindow && !this.previewWindow.closed) {
            this.previewWindow.focus();

            return;
        }

        this.previewWindow = window.open(this.previewStore.renderRoute);
        this.previewWindow.addEventListener('beforeunload', () => {
            this.previewWindow = undefined;
        });
    };

    render() {
        return null;
    }
}

export default Preview;
