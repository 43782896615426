export default function validateDisplayOption(name) {
    return (
        name === 'leftTop' ||
        name === 'top' ||
        name === 'rightTop' ||
        name === 'left' ||
        name === 'middle' ||
        name === 'right' ||
        name === 'leftBottom' ||
        name === 'bottom' ||
        name === 'rightBottom'
    );
}
