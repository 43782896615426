import React from 'react';
import { ResourceFormStore } from 'sulu-admin-bundle/containers';
import MediaVersionUploadComponent from '../../MediaVersionUpload';

class MediaVersionUpload extends React.Component {
    resourceStore;

    constructor(props) {
        super(props);
        const { formInspector } = this.props;

        const formStore = formInspector.formStore;
        if (!(formStore instanceof ResourceFormStore)) {
            throw new Error('The MediaVersionUpload field needs a ResourceFormStore instance!');
        }

        this.resourceStore = formStore.resourceStore;

        const locale = this.resourceStore.locale;
        if (!locale) {
            throw new Error('The resourceStore for the MediaVersionUpload must have a locale');
        }
    }

    render() {
        return <MediaVersionUploadComponent onSuccess={this.props.onSuccess} resourceStore={this.resourceStore} />;
    }
}

export default MediaVersionUpload;
