import { observer } from 'mobx-react';
import React from 'react';
import classNames from 'classnames';
import Header from 'sulu-admin-bundle/components/Table/Header';
import Body from 'sulu-admin-bundle/components/Table/Body';
import Row from 'sulu-admin-bundle/components/Table/Row';
import Cell from 'sulu-admin-bundle/components/Table/Cell';
import HeaderCell from 'sulu-admin-bundle/components/Table/HeaderCell';
import tableStyles from 'sulu-admin-bundle/components/Table/table.scss';
import additionalTableStyles from './table.scss';

@observer
class Table extends React.Component {
    static defaultProps = {
        buttons: [],
        selectMode: 'none',
        skin: 'dark',
    };

    static Header = Header;

    static Body = Body;

    static Row = Row;

    static Cell = Cell;

    static HeaderCell = HeaderCell;

    cloneHeader = (originalHeader, allSelected) => {
        if (!originalHeader) {
            return null;
        }

        const { buttons, onAllSelectionChange, selectMode, selectInFirstCell, skin } = this.props;

        return React.cloneElement(originalHeader, {
            allSelected,
            buttons: [...buttons, ...(originalHeader.props.buttons || [])],
            onAllSelectionChange: onAllSelectionChange ? this.handleAllSelectionChange : undefined,
            selectMode,
            selectInFirstCell,
            skin,
        });
    };

    cloneBody = (originalBody) => {
        if (!originalBody) {
            return null;
        }

        return React.cloneElement(originalBody, {
            buttons: this.props.buttons,
            selectMode: this.props.selectMode,
            selectInFirstCell: this.props.selectInFirstCell,
            onRowSelectionChange: this.props.onRowSelectionChange ? this.handleRowSelectionChange : undefined,
            onRowExpand: this.handleRowExpand,
            onRowCollapse: this.handleRowCollapse,
        });
    };

    checkAllRowsSelected = (body) => {
        const rows = body.props.children;

        if (!rows) {
            return false;
        }

        const rowSelections = React.Children.map(rows, (row) => row.props.selected || row.props.disabled);

        return !rowSelections.includes(false);
    };

    createTablePlaceholderArea = () => {
        const { placeholderText } = this.props;

        return (
            <div className={tableStyles.tablePlaceholderArea}>
                {placeholderText && <div className={tableStyles.tablePlaceholderText}>{placeholderText}</div>}
            </div>
        );
    };

    handleRowExpand = (rowId) => {
        const { onRowExpand } = this.props;
        if (onRowExpand) {
            onRowExpand(rowId);
        }
    };

    handleRowCollapse = (rowId) => {
        const { onRowCollapse } = this.props;
        if (onRowCollapse) {
            onRowCollapse(rowId);
        }
    };

    handleAllSelectionChange = (checked) => {
        const { onAllSelectionChange } = this.props;
        if (onAllSelectionChange) {
            onAllSelectionChange(checked);
        }
    };

    handleRowSelectionChange = (rowId, selected) => {
        const { onRowSelectionChange } = this.props;
        if (onRowSelectionChange) {
            onRowSelectionChange(rowId, selected);
        }
    };

    render() {
        const { buttons, children, skin } = this.props;
        let body;
        let header;

        React.Children.forEach(children, (child) => {
            if (!child) {
                return;
            }

            switch (child.type) {
                case Header:
                    header = child;
                    break;
                case Body:
                    body = child;
                    break;
                default:
                    throw new Error(
                        'The Table component only accepts the following children types: ' +
                            [Header.name, Body.name].join(', '),
                    );
            }
        });

        const clonedBody = this.cloneBody(body);
        const emptyBody = clonedBody && React.Children.count(clonedBody.props.children) === 0;
        const allRowsSelected = clonedBody && !emptyBody ? this.checkAllRowsSelected(clonedBody) : false;
        const clonedHeader = this.cloneHeader(header, allRowsSelected);

        const tableClass = classNames(
            tableStyles.tableContainer,
            tableStyles[skin],
            {
                [tableStyles.hasButtons]: buttons.length > 0,
            },
            additionalTableStyles.visibleButtons,
        );
        return (
            <div className={tableClass}>
                <table className={tableStyles.table}>
                    {clonedHeader}
                    {clonedBody}
                </table>
                {emptyBody && this.createTablePlaceholderArea()}
            </div>
        );
    }
}

export default Table;
