import React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import classNames from 'classnames';
import Icon from 'sulu-admin-bundle/components/Icon';
import NavigationItem from './NavigationItem';
import navigationStyles from 'sulu-admin-bundle/components/Navigation/navigation.scss';
import UserSection from 'sulu-admin-bundle/components/Navigation/UserSection';

@observer
class Navigation extends React.Component {
    static defaultProps = {
        appVersion: undefined,
        pinned: false,
        userImage: undefined,
    };

    static Item = NavigationItem;

    @observable expandedChild = null;

    @action setExpandedChild(value) {
        this.expandedChild = value;
    }

    constructor(props) {
        super(props);

        this.findDefaultExpandedChild(this.props.children);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.children !== this.props.children) {
            this.findDefaultExpandedChild(this.props.children);
        }
    }

    findDefaultExpandedChild = (children) => {
        let newExpandedChild = null;
        React.Children.forEach(children, (child) => {
            if (child.props.children) {
                React.Children.forEach(child.props.children, (subChild) => {
                    if (subChild.props.active) {
                        newExpandedChild = child.props.value;
                    }
                });
            }
        });

        this.setExpandedChild(newExpandedChild);
    };

    handleItemClick = (value) => {
        if (this.expandedChild === value) {
            this.setExpandedChild(null);

            return;
        }

        this.setExpandedChild(value);
        this.props.onItemClick(value);
    };

    cloneChildren() {
        return React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, {
                children: child.props.children
                    ? React.Children.map(child.props.children, (subChild) => {
                          if (!subChild) {
                              return;
                          }

                          return React.cloneElement(subChild, {
                              onClick: this.handleItemClick,
                          });
                      })
                    : undefined,
                expanded:
                    child.props.value === this.expandedChild ||
                    (child.props.children &&
                        child.props.children.some((child) => child.props.value === this.expandedChild)),
                onClick: this.handleItemClick,
            });
        });
    }

    closeNavigation = () => {
        const { closeNavigation } = this.props;

        if (closeNavigation) {
            closeNavigation();
        }
    };

    render() {
        const { pinned, username, userImage, onLogoutClick, onProfileClick, suluVersion } = this.props;

        const pinClass = classNames(navigationStyles.pin, { [navigationStyles.active]: pinned });

        return (
            <div className={navigationStyles.navigation}>
                <div className={navigationStyles.header}>
                    <span className={navigationStyles.logo} title={suluVersion}>
                        <Icon name="su-sulu-logo" />
                    </span>

                    <button className={pinClass} onClick={this.closeNavigation} type="button">
                        <Icon
                            className={navigationStyles.pinIcon}
                            name="su-times"
                            style={{
                                animation: 'none !important',
                            }}
                        />
                    </button>
                </div>

                <div className={navigationStyles.items}>{this.cloneChildren()}</div>

                <div className={navigationStyles.footer}>
                    <UserSection
                        onLogoutClick={onLogoutClick}
                        onProfileClick={onProfileClick}
                        userImage={userImage}
                        username={username}
                    />
                </div>
            </div>
        );
    }
}

export default Navigation;
