import React from 'react';
import { action, when } from 'mobx';
import { observer } from 'mobx-react';
import { Divider } from 'sulu-admin-bundle/components';
import { translate } from 'sulu-admin-bundle/utils/Translator';
import MultiMediaDropzone from '../MultiMediaDropzone';
import CollectionSection from './CollectionSection';
import MediaList from '../List/MediaList';

@observer
class MediaCollection extends React.Component {
    static defaultProps = {
        hideUploadAction: false,
        overlayType: 'overlay',
    };

    static addable = true;
    static deletable = true;
    static editable = true;
    static securable = true;

    handleCollectionNavigate = (collectionId) => {
        this.props.onCollectionNavigate(collectionId);
    };

    @action handleUpload = (media) => {
        const { mediaListStore } = this.props;

        mediaListStore.reload();

        when(
            () => !mediaListStore.loading,
            () => media.forEach((mediaItem) => mediaListStore.select(mediaItem)),
        );
    };

    @action handleUploadError = (errorResponses) => {
        const { mediaListStore, onUploadError } = this.props;

        if (onUploadError) {
            onUploadError(errorResponses);
        }

        mediaListStore.reload();
    };

    render() {
        const {
            onDeleteError,
            className,
            collectionListStore,
            collectionStore,
            hideUploadAction,
            locale,
            overlayType,
            mediaListRef,
            mediaListStore,
            onMediaNavigate,
            onUploadOverlayClose,
            onUploadOverlayOpen,
            uploadOverlayOpen,
        } = this.props;

        const { locked, permissions } = collectionStore;
        const listActions = [];

        const addable = !locked && (permissions.add !== undefined ? permissions.add : MediaCollection.addable);
        const editable = !locked && (permissions.edit !== undefined ? permissions.edit : MediaCollection.editable);
        const deletable =
            !locked && (permissions.delete !== undefined ? permissions.delete : MediaCollection.deletable);

        if (addable && !hideUploadAction) {
            listActions.push({
                disabled: collectionStore.loading,
                icon: 'su-upload',
                label: translate('sulu_media.upload_file'),
                onClick: onUploadOverlayOpen,
            });
        }

        return (
            <MultiMediaDropzone
                className={className}
                collectionId={collectionStore.id}
                disabled={collectionStore.loading || !addable}
                locale={locale}
                onClose={onUploadOverlayClose}
                onOpen={onUploadOverlayOpen}
                onUpload={this.handleUpload}
                onUploadError={this.handleUploadError}
                open={uploadOverlayOpen}
            >
                <CollectionSection
                    addable={addable}
                    deletable={deletable}
                    editable={editable}
                    listStore={collectionListStore}
                    locale={locale}
                    onCollectionNavigate={this.handleCollectionNavigate}
                    onDeleteError={onDeleteError}
                    overlayType={overlayType}
                    resourceStore={collectionStore.resourceStore}
                    securable={false}
                />
                <Divider />
                <MediaList
                    actions={listActions}
                    adapters={['media_card_overview', 'media-table']}
                    onDeleteError={onDeleteError}
                    onItemClick={onMediaNavigate}
                    ref={mediaListRef}
                    store={mediaListStore}
                />
            </MultiMediaDropzone>
        );
    }
}

export default MediaCollection;
