import React from 'react';
import { action, computed, toJS, observable, isArrayLike } from 'mobx';
import { observer } from 'mobx-react';
import equals from 'fast-deep-equal';
import Dialog from 'sulu-admin-bundle/components/Dialog';
import FormContainer, { resourceFormStoreFactory } from 'sulu-admin-bundle/containers/Form';
import { withToolbar } from 'sulu-admin-bundle/containers/Toolbar';
import ResourceStore from 'sulu-admin-bundle/stores/ResourceStore';
import CollaborationStore from 'sulu-admin-bundle/stores/CollaborationStore';
import { translate } from 'sulu-admin-bundle/utils/Translator';
import formToolbarActionRegistry from 'sulu-admin-bundle/views/Form/registries/formToolbarActionRegistry';
import formStyles from 'sulu-admin-bundle/views/Form/form.scss';

const FORM_STORE_UPDATE_ROUTE_HOOK_PRIORITY = 2048;

const HAS_CHANGED_ERROR_CODE = 1102;

@observer
class Form extends React.Component {
    resourceStore;
    resourceFormStore;
    collaborationStore;
    form;
    @observable errors = [];
    showSuccess = observable.box(false);
    @observable toolbarActions = [];
    @observable showDirtyWarning = false;
    @observable showHasChangedWarning = false;
    postponedSaveOptions;
    postponedUpdateRouteMethod;
    postponedRoute;
    postponedRouteAttributes;
    checkFormStoreDirtyStateBeforeNavigationDisposer;

    @computed get hasOwnResourceStore() {
        const { resourceStore } = this.props;

        return this.resourceKey && resourceStore.resourceKey !== this.resourceKey;
    }

    @computed.struct get locales() {
        const {
            locales: propsLocales,
            route: {
                options: { locales: routeLocales },
            },
        } = this.props;

        return routeLocales || propsLocales;
    }

    @computed get id() {
        const {
            router: {
                attributes: { id },
            },
        } = this.props;

        if (id !== undefined && typeof id !== 'string' && typeof id !== 'number') {
            throw new Error('The "id" router attribute must be a string or a number if given!');
        }

        return id;
    }

    @computed get resourceKey() {
        const {
            route: {
                options: { resourceKey },
            },
        } = this.props;

        return resourceKey;
    }

    @computed get formKey() {
        const {
            route: {
                options: { formKey },
            },
        } = this.props;

        if (!formKey) {
            throw new Error('The route does not define the mandatory "formKey" option');
        }

        return formKey;
    }

    @computed get formStoreOptions() {
        const {
            attributes,
            route: {
                options: { requestParameters = {}, routerAttributesToFormRequest = {} },
            },
        } = this.props.router;

        const formStoreOptions = requestParameters || {};
        Object.keys(toJS(routerAttributesToFormRequest)).forEach((key) => {
            const formOptionKey = routerAttributesToFormRequest[key];
            const attributeName = isNaN(key) ? key : toJS(routerAttributesToFormRequest[key]);

            formStoreOptions[formOptionKey] = attributes[attributeName];
        });

        return formStoreOptions;
    }

    @computed get metadataOptions() {
        const {
            attributes,
            route: {
                options: { routerAttributesToFormMetadata = {}, metadataRequestParameters = {} },
            },
        } = this.props.router;

        const metadataOptions = { ...metadataRequestParameters };

        Object.keys(toJS(routerAttributesToFormMetadata)).forEach((key) => {
            const listOptionKey = routerAttributesToFormMetadata[key];
            const attributeName = isNaN(key) ? key : toJS(routerAttributesToFormMetadata[key]);

            metadataOptions[listOptionKey] = attributes[attributeName];
        });

        return metadataOptions;
    }

    constructor(props) {
        super(props);

        const { router } = this.props;

        this.createResourceFormStore();
        this.createCollaborationStore();

        this.checkFormStoreDirtyStateBeforeNavigationDisposer = router.addUpdateRouteHook(
            this.checkFormStoreDirtyStateBeforeNavigation,
            FORM_STORE_UPDATE_ROUTE_HOOK_PRIORITY,
        );
    }

    createResourceFormStore = () => {
        const { resourceStore, router } = this.props;
        const {
            route: {
                options: { idQueryParameter },
            },
        } = router;

        if (!resourceStore) {
            throw new Error(
                'The view "Form" needs a resourceStore to work properly.' +
                    'Did you maybe forget to make this view a child of a "ResourceTabs" view?',
            );
        }

        if (this.hasOwnResourceStore) {
            let locale = resourceStore.locale;
            if (!locale && this.locales) {
                locale = observable.box();
            }

            if (idQueryParameter) {
                this.resourceStore = new ResourceStore(
                    this.resourceKey,
                    this.id,
                    { locale },
                    this.formStoreOptions,
                    idQueryParameter,
                );
            } else {
                this.resourceStore = new ResourceStore(this.resourceKey, this.id, { locale }, this.formStoreOptions);
            }
        } else {
            this.resourceStore = resourceStore;
        }

        this.resourceFormStore = resourceFormStoreFactory.createFromResourceStore(
            this.resourceStore,
            this.formKey,
            this.formStoreOptions,
            this.metadataOptions,
        );

        if (this.resourceStore.locale) {
            router.bind('locale', this.resourceStore.locale);
        }
    };

    createCollaborationStore = () => {
        if (this.resourceKey && this.id) {
            this.collaborationStore = new CollaborationStore(this.resourceKey, this.id);
        }
    };

    @action checkFormStoreDirtyStateBeforeNavigation = (route, attributes, updateRouteMethod) => {
        if (route.name && route.name === this.props.route.name && route.name.includes('add_form')) {
            return false;
        }

        if (!this.resourceFormStore.dirty) {
            return true;
        }

        const { route: viewRoute, router } = this.props;
        if (router.route !== viewRoute) {
            return true;
        }

        if (
            this.showDirtyWarning === true &&
            this.postponedRoute === route &&
            equals(this.postponedRouteAttributes, attributes) &&
            this.postponedUpdateRouteMethod === updateRouteMethod
        ) {
            return true;
        }

        if (!route && !attributes && !updateRouteMethod) {
            return false;
        }

        this.showDirtyWarning = true;
        this.postponedUpdateRouteMethod = updateRouteMethod;
        this.postponedRoute = route;
        this.postponedRouteAttributes = attributes;

        return false;
    };

    @action componentDidMount() {
        const { resourceStore: parentResourceStore, router } = this.props;
        const {
            route: {
                options: { toolbarActions: rawToolbarActions },
            },
        } = router;

        if (!isArrayLike(rawToolbarActions)) {
            throw new Error('The view "Form" needs some defined toolbarActions to work properly!');
        }

        const toolbarActions = toJS(rawToolbarActions);

        toolbarActions.forEach((toolbarAction) => {
            if (typeof toolbarAction !== 'object') {
                throw new Error(
                    'The value of a toolbarAction entry must be an object, but ' + typeof toolbarAction + ' was given!',
                );
            }
        });

        this.toolbarActions = toolbarActions.map(
            (toolbarAction) =>
                new (formToolbarActionRegistry.get(toolbarAction.type))(
                    this.resourceFormStore,
                    this,
                    router,
                    this.locales,
                    toolbarAction.options,
                    parentResourceStore,
                ),
        );
    }

    componentDidUpdate(prevProps) {
        if (!equals(this.props.locales, prevProps.locales)) {
            this.toolbarActions.forEach((toolbarAction) => {
                toolbarAction.setLocales(this.locales);
            });
        }
    }

    componentWillUnmount() {
        this.checkFormStoreDirtyStateBeforeNavigationDisposer();

        this.resourceFormStore.destroy();

        if (this.collaborationStore) {
            this.collaborationStore.destroy();
        }

        if (this.hasOwnResourceStore) {
            this.resourceStore.destroy();
        }

        this.toolbarActions.forEach((toolbarAction) => toolbarAction.destroy());
    }

    @action showSuccessSnackbar = () => {
        this.showSuccess.set(true);
    };

    @action submit = (options) => {
        if (!this.form) {
            throw new Error('The form ref has not been set! This should not happen and is likely a bug.');
        }
        this.form.submit(options);
    };

    handleSubmit = (options) => {
        if (typeof options === 'string') {
            options = { action: options };
        }

        return this.save(options);
    };

    handleSuccess = () => {
        this.showSuccessSnackbar();
    };

    save = (options) => {
        const { resourceStore, router } = this.props;

        const {
            attributes,
            route: {
                options: { editView, routerAttributesToEditView },
            },
        } = router;

        if (editView) {
            resourceStore.destroy();
        }

        const saveOptions = { ...options };

        const editViewParameters = {};

        if (routerAttributesToEditView) {
            Object.keys(toJS(routerAttributesToEditView)).forEach((key) => {
                const formOptionKey = routerAttributesToEditView[key];
                const attributeName = isNaN(key) ? key : routerAttributesToEditView[key];

                editViewParameters[formOptionKey] = attributes[attributeName];
            });
        }

        return this.resourceFormStore
            .save(saveOptions)
            .then((response) => {
                this.showSuccessSnackbar();
                this.clearErrors();

                if (editView) {
                    router.navigate(editView, {
                        id: resourceStore.id,
                        locale: resourceStore.locale,
                        ...editViewParameters,
                    });
                }

                return response;
            })
            .catch(
                action((error) => {
                    if (error.code === HAS_CHANGED_ERROR_CODE) {
                        this.showHasChangedWarning = true;
                        this.postponedSaveOptions = options;

                        return;
                    }

                    this.errors.push(error.detail || error.title || translate('sulu_admin.form_save_server_error'));
                }),
            );
    };

    navigateBack = () => {
        const { router } = this.props;
        const {
            attributes,
            route: {
                options: { backView, routerAttributesToBackView },
            },
        } = router;

        if (!backView) {
            return;
        }

        const backViewParameters = {};

        if (routerAttributesToBackView) {
            Object.keys(toJS(routerAttributesToBackView)).forEach((key) => {
                const formOptionKey = routerAttributesToBackView[key];
                const attributeName = isNaN(key) ? key : routerAttributesToBackView[key];

                backViewParameters[formOptionKey] = attributes[attributeName];
            });
        }

        if (this.resourceStore.locale) {
            backViewParameters.locale = this.resourceStore.locale.get();
        }

        router.restore(backView, backViewParameters);
    };

    handleError = () => {
        this.errors.push(translate('sulu_admin.form_contains_invalid_values'));
    };

    @action clearErrors = () => {
        this.errors.splice(0, this.errors.length);
    };

    handleMissingTypeCancel = () => {
        this.navigateBack();
    };

    @action handleDirtyWarningCancelClick = () => {
        this.showDirtyWarning = false;
        this.postponedUpdateRouteMethod = undefined;
        this.postponedRoute = undefined;
        this.postponedRouteAttributes = undefined;
    };

    @action handleDirtyWarningConfirmClick = () => {
        if (!this.postponedUpdateRouteMethod || !this.postponedRoute || !this.postponedRouteAttributes) {
            throw new Error('Some routing information is missing. This should not happen and is likely a bug.');
        }

        this.postponedUpdateRouteMethod(this.postponedRoute.name, this.postponedRouteAttributes);
        this.postponedUpdateRouteMethod = undefined;
        this.postponedRoute = undefined;
        this.postponedRouteAttributes = undefined;
        this.showDirtyWarning = false;
    };

    @action handleHasChangedWarningCancelClick = () => {
        this.showHasChangedWarning = false;
        this.postponedSaveOptions = undefined;
    };

    @action handleHasChangedWarningConfirmClick = () => {
        this.save({ ...this.postponedSaveOptions, force: true });
        this.showHasChangedWarning = false;
        this.postponedSaveOptions = undefined;
    };

    setFormRef = (form) => {
        this.form = form;
    };

    getDirtyWarningCancelTitle() {
        const routeName = this.props?.router?.route?.name;

        switch (routeName) {
            case 'article_edit_form.details':
                return translate('sulu_admin.article_edit_form.details.dirty_warning_dialog_title');
            case 'article_edit_form':
                return translate('sulu_admin.article_edit_form.dirty_warning_dialog_title');
            case 'article_edit_form.details_seo':
                return translate('sulu_admin.article_edit_form.details_seo.dirty_warning_dialog_title');
            case 'article_edit_form.activity':
                return translate('sulu_admin.article_edit_form.activity.dirty_warning_dialog_title');
            case 'article_tag_edit_form.details':
                return translate('sulu_admin.article_tag_edit_form.details.dirty_warning_dialog_title');
            case 'article_tag_edit_form':
                return translate('sulu_admin.article_tag_edit_form.dirty_warning_dialog_title');
            case 'career_edit_form':
                return translate('sulu_admin.career_edit_form.dirty_warning_dialog_title');
            case 'career_edit_form.details':
                return translate('sulu_admin.career_edit_form.details.dirty_warning_dialog_title');
            case 'career_edit_form.details_seo':
                return translate('sulu_admin.career_edit_form.details_seo.dirty_warning_dialog_title');
            case 'career_edit_form.activity':
                return translate('sulu_admin.career_edit_form.activity.dirty_warning_dialog_title');
            case 'employee_edit_form':
                return translate('sulu_admin.employee_edit_form.dirty_warning_dialog_title');
            case 'employee_edit_form.details':
                return translate('sulu_admin.employee_edit_form.details.dirty_warning_dialog_title');
            case 'department_edit_form':
                return translate('sulu_admin.department_edit_form.dirty_warning_dialog_title');
            case 'department_edit_form.details':
                return translate('sulu_admin.department_edit_form.details.dirty_warning_dialog_title');
            case 'investment_edit_form':
                return translate('sulu_admin.investment_edit_form.dirty_warning_dialog_title');
            case 'investment_edit_form.details':
                return translate('sulu_admin.investment_edit_form.details.dirty_warning_dialog_title');
            case 'investment_edit_form.details_seo':
                return translate('sulu_admin.investment_edit_form.details_seo.dirty_warning_dialog_title');
            case 'investment_edit_form.activity':
                return translate('sulu_admin.investment_edit_form.activity.dirty_warning_dialog_title');
            case 'investment_tag_edit_form':
                return translate('sulu_admin.investment_tag_edit_form.dirty_warning_dialog_title');
            case 'investment_tag_edit_form.details':
                return translate('sulu_admin.investment_tag_edit_form.details.dirty_warning_dialog_title');
            case 'project_edit_form':
                return translate('sulu_admin.project_edit_form.dirty_warning_dialog_title');
            case 'project_edit_form.details':
                return translate('sulu_admin.project_edit_form.details.dirty_warning_dialog_title');
            case 'project_edit_form.details_seo':
                return translate('sulu_admin.project_edit_form.details_seo.dirty_warning_dialog_title');
            case 'project_edit_form.activity':
                return translate('sulu_admin.project_edit_form.activity.dirty_warning_dialog_title');
            case 'project_tag_edit_form':
                return translate('sulu_admin.project_tag_edit_form.dirty_warning_dialog_title');
            case 'project_tag_edit_form.details':
                return translate('sulu_admin.project_tag_edit_form.details.dirty_warning_dialog_title');
            default:
                break;
        }

        return translate('sulu_admin.dirty_warning_dialog_title');
    }

    render() {
        const {
            route: {
                options: { titleVisible = false },
            },
            router,
            title,
        } = this.props;

        return (
            <div className={formStyles.form}>
                {titleVisible && title && <h1>{title}</h1>}
                <FormContainer
                    onError={this.handleError}
                    onMissingTypeCancel={this.handleMissingTypeCancel}
                    onSubmit={this.handleSubmit}
                    onSuccess={this.handleSuccess}
                    ref={this.setFormRef}
                    router={router}
                    store={this.resourceFormStore}
                />
                {this.toolbarActions.map((toolbarAction, index) => toolbarAction.getNode(index))}
                <Dialog
                    cancelText={translate('sulu_admin.cancel')}
                    confirmText={translate('sulu_admin.dirty_confirm')}
                    onCancel={this.handleDirtyWarningCancelClick}
                    onConfirm={this.handleDirtyWarningConfirmClick}
                    open={this.showDirtyWarning}
                    title={this.getDirtyWarningCancelTitle()}
                >
                    {translate('sulu_admin.dirty_warning_dialog_text')}
                </Dialog>
                <Dialog
                    cancelText={translate('sulu_admin.cancel')}
                    confirmText={translate('sulu_admin.confirm')}
                    onCancel={this.handleHasChangedWarningCancelClick}
                    onConfirm={this.handleHasChangedWarningConfirmClick}
                    open={this.showHasChangedWarning}
                    title={translate('sulu_admin.has_changed_warning_dialog_title')}
                >
                    {translate('sulu_admin.has_changed_warning_dialog_text')}
                </Dialog>
            </div>
        );
    }
}

export default withToolbar(Form, function () {
    const { router } = this.props;
    const {
        route: {
            options: { backView },
        },
    } = router;
    const { errors, resourceStore, showSuccess } = this;

    const backButton = backView
        ? {
              onClick: this.navigateBack,
          }
        : undefined;
    const locale = this.locales
        ? {
              value: resourceStore.locale.get(),
              onChange: (locale) => {
                  router.navigate(router.route.name, { ...router.attributes, locale });
              },
              options: this.locales.map((locale) => ({
                  value: locale,
                  label: locale,
              })),
          }
        : undefined;

    const items = this.toolbarActions
        .map((toolbarAction) => toolbarAction.getToolbarItemConfig())
        .filter((item) => item != null);

    const icons = [];

    const warnings = [];
    if (this.collaborationStore && this.collaborationStore.collaborations.length > 0) {
        warnings.push(
            [
                translate('sulu_admin.form_used_by'),
                this.collaborationStore.collaborations.map((collaboration) => collaboration.fullName).join(', '),
            ].join(' '),
        );
    }

    return {
        backButton,
        errors,
        locale,
        items,
        icons,
        showSuccess,
        warnings,
    };
});
