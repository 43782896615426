import React, { Fragment } from 'react';
import { action, computed, observable } from 'mobx';
import ResourceRequester from 'sulu-admin-bundle/services/ResourceRequester';
import { translate } from 'sulu-admin-bundle/utils';
import { AbstractFormToolbarAction } from 'sulu-admin-bundle/views';
import styles from './toolbar.scss';
import snackbarStore from 'sulu-admin-bundle/stores/snackbarStore';
import Dialog from '../../components/Dialog';

export default class PublishToolbarAction extends AbstractFormToolbarAction {
    @observable loading = false;

    @computed get property() {
        const { property } = this.options;

        if (typeof property !== 'string') {
            throw new Error('The "property" option must be a string value!');
        }

        return property;
    }

    @computed get label() {
        const { label } = this.options;

        if (typeof label !== 'string') {
            throw new Error('The "label" option must be a string value!');
        }

        return label;
    }

    @computed get activateAction() {
        const { activate } = this.options;

        if (typeof activate !== 'string') {
            throw new Error('The "activate" option must be a string value!');
        }

        return activate;
    }

    @computed get deactivateAction() {
        const { deactivate } = this.options;

        if (typeof deactivate !== 'string') {
            throw new Error('The "deactivate" option must be a string value!');
        }

        return deactivate;
    }

    @computed get isActive() {
        return this.resourceFormStore.data[this.property];
    }

    @observable showDialog = false;
    @observable referencingResourcesData = undefined;
    @observable dependantResourcesData = undefined;

    @computed get allowConflictDeletion() {
        const { allow_conflict_deletion: allowConflictDeletion = true } = this.options;

        return !!allowConflictDeletion;
    }

    handleDialogCancel = () => {
        this.closeDialog();
    };

    handleDialogConfirm = () => {
        this.publish();
    };

    @action closeDialog = () => {
        this.showDialog = false;
    };

    renderDialog(postfix) {
        const { formKey } = this.resourceFormStore;
        let title = translate('sulu_admin.publish_warning_title');

        switch (formKey) {
            case 'article_details_edit':
                title = translate('sulu_admin.article.publish_warning_title');
                break;

            case 'article_tag_details_edit':
                title = translate('');
                break;
            case 'career_details_edit':
                title = translate('sulu_admin.career.publish_warning_title');
                break;
            case 'employee_details_edit':
                title = translate('sulu_admin.employee.publish_warning_title');
                break;
            case 'department_details_edit':
                title = translate('sulu_admin.department.publish_warning_title');
                break;
            case 'investment_details_edit':
                title = translate('sulu_admin.investment.publish_warning_title');
                break;
            case 'investment_tag_details_edit':
                title = translate('sulu_admin.investment_tag.publish_warning_title');
                break;
            case 'project_details_edit':
                title = translate('sulu_admin.project.publish_warning_title');
                break;
            case 'project_tag_details_edit':
                title = translate('sulu_admin.project_tag.publish_warning_title');
                break;
            case 'energon_user_edit':
                title = translate('sulu_admin.energon_user.publish_warning_title');
                break;
            default:
                break;
        }

        return (
            <Dialog
                className="publishDialog"
                cancelText={translate('sulu_admin.cancel')}
                confirmLoading={this.resourceFormStore.loading}
                confirmText={translate('sulu_admin.publish')}
                onCancel={this.handleDialogCancel}
                onConfirm={this.handleDialogConfirm}
                open={this.showDialog}
                title={title}
                confirmButtonClassName={styles.confirmPublishButton}
            >
                {translate('sulu_admin.publish_warning_text')}
            </Dialog>
        );
    }

    getNode() {
        return <Fragment key={'publish_content'}>{this.renderDialog()}</Fragment>;
    }

    getToolbarItemConfig() {
        if (!this.resourceFormStore.data.canChange) {
            return;
        }

        const disabled =
            this.resourceFormStore.loading || this.resourceFormStore.dirty || !this.resourceFormStore.data.id;

        return {
            type: 'toggler',
            onClick: action(() => {
                if (disabled) return;
                if (!this.resourceFormStore.data.wasPublished) {
                    this.showDialog = true;
                } else {
                    this.publish();
                }
            }),
            disabled,
            label: translate(this.label),
            loading: this.loading,
            value: this.isActive,
        };
    }

    @action publish = () => {
        const {
            resourceKey,
            locale,
            data: { id },
        } = this.resourceFormStore;

        this.loading = true;
        ResourceRequester.post(resourceKey, undefined, {
            action: this.isActive ? this.deactivateAction : this.activateAction,
            locale,
            id,
        })
            .then(
                action((response) => {
                    this.resourceFormStore.change(this.property, response[this.property], { isServerValue: true });
                    if ('wasPublished' in response) {
                        this.resourceFormStore.change('wasPublished', response.wasPublished, {
                            isServerValue: true,
                        });
                    }

                    this.loading = false;

                    let successMessage;

                    this.closeDialog();

                    if (this.isActive) {
                        switch (resourceKey) {
                            case 'careers':
                                successMessage = translate('careers_published');
                                break;
                            case 'articles':
                                successMessage = translate('articles_published');
                                break;
                            case 'investment':
                                successMessage = translate('investment_published');
                                break;
                            case 'projects':
                                successMessage = translate('projects_published');
                                break;
                            case 'employees':
                                successMessage = translate('employees_published');
                                break;

                            default:
                                break;
                        }
                    }

                    if (successMessage) {
                        setTimeout(() => {
                            snackbarStore.add(
                                {
                                    type: 'success',
                                    text: successMessage,
                                },
                                2500,
                            );
                        }, 300);
                    } else {
                        this.form.showSuccessSnackbar();
                    }
                }),
            )
            .catch(
                action((response) => {
                    response.json().then(
                        action((error) => {
                            this.form.errors.push(error.detail || error.title || translate('sulu_trash.restore_error'));
                            this.loading = false;
                        }),
                    );
                }),
            );
    };
}
