import equals from 'fast-deep-equal';
import { reaction, toJS } from 'mobx';
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { MultiAutoComplete } from 'sulu-admin-bundle/containers';
import MultiSelectionStore from 'sulu-admin-bundle/stores/MultiSelectionStore';

export default class InvestmentTagAutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainWebspace: props.data?.mainWebspace ?? props.router?.attributes?.webspace,
            locale: props.data?.locale ?? props.router?.attributes?.locale,
            key: 0,
        };
        const {
            data: { mainWebspace, locale },
        } = this.props;

        this.createStore(props.value, mainWebspace, locale);
    }

    createStore(defaultValue, mainWebspace, locale) {
        this.selectionStore = new MultiSelectionStore('investment_tags_autocomplete', defaultValue, undefined, 'ids', {
            investmentWebspace: mainWebspace,
            investmentLocale: locale,
        });

        this.disposeReaction = reaction(
            () => this.selectionStore.items.map((item) => item.id),
            (loadedItemIds) => {
                const { onChange, value } = this.props;

                if (!equals(toJS(value), toJS(loadedItemIds))) {
                    onChange(loadedItemIds);
                }
            },
        );
    }

    componentDidMount() {
        window.rerenderAutoComplete = (mainWebspace, locale) => {
            this.createStore(undefined, mainWebspace, locale);

            this.setState((prevState) => ({
                key: prevState.key + 1,
                mainWebspace,
                locale,
            }));
        };
    }

    componentWillUnmount() {
        delete window.rerenderDepartmentAutoComplete;
    }

    render() {
        const { dataPath, disabled } = this.props;

        return (
            <MultiAutoComplete
                key={`investment-tag-${this.state.key}`}
                selectionStore={this.selectionStore}
                searchProperties={['title']}
                displayProperty="title"
                allowAdd
                disabled={disabled}
                id={dataPath}
                options={{
                    investmentWebspace: this.state.mainWebspace,
                    investmentLocale: this.state.locale,
                }}
            />
        );
    }
}
