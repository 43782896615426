import { observer } from 'mobx-react';
import React from 'react';
import Pagination from 'sulu-admin-bundle/components/Pagination';
import { translate } from 'sulu-admin-bundle/utils';
import FlatStructureStrategy from 'sulu-admin-bundle/containers/List/structureStrategies/FlatStructureStrategy';
import DefaultLoadingStrategy from 'sulu-admin-bundle/containers/List/loadingStrategies/DefaultLoadingStrategy';
import AbstractTableAdapter from 'sulu-admin-bundle/containers/List/adapters/AbstractTableAdapter';
import listFieldTransformerRegistry from 'sulu-admin-bundle/containers/List/registries/listFieldTransformerRegistry';
import tableStyles from '../../styles/table.scss';
import Tooltip from 'sulu-admin-bundle/components/Tooltip';
import Table from '../components/Table';

const renderTooltip = (webspace) => {
    switch (webspace) {
        case 'energon-stavebni.cz':
            return (
                <Tooltip key="EST" label="energon-stavebni.cz">
                    <b>EST</b>&nbsp;
                </Tooltip>
            );
        case 'energon-consulting.cz':
            return (
                <Tooltip key="ECO" label="energon-consulting.cz">
                    <b>ECO</b>&nbsp;
                </Tooltip>
            );
        case 'energon-solutions.cz':
            return (
                <Tooltip key="EES" label="energon-solutions.cz">
                    <b>EES</b>&nbsp;
                </Tooltip>
            );
        case 'energon-grid.cz':
            return (
                <Tooltip key="EGD" label="energon-grid.cz">
                    <b>EGD</b>&nbsp;
                </Tooltip>
            );
        case 'energon.cz':
            return (
                <Tooltip key="EHO" label="energon.cz">
                    <b>EHO</b>&nbsp;
                </Tooltip>
            );
        default:
            return webspace;
    }
};

@observer
class TableAdapter extends AbstractTableAdapter {
    static LoadingStrategy = DefaultLoadingStrategy;

    static StructureStrategy = FlatStructureStrategy;

    static icon = 'su-align-justify';

    renderCells(item) {
        const schemaKeys = Object.keys(this.schema);

        let published = false;

        if (schemaKeys.find((key) => key === 'published') !== undefined && 'published' in item) {
            published = item.published;
        }

        return schemaKeys
            .filter((key) => key !== 'published')
            .map((schemaKey, index) => {
                if (schemaKey === 'additionalWebspaces' && 'additionalWebspaces' in item) {
                    const value = item.additionalWebspaces.map((webspace) => {
                        return renderTooltip(webspace);
                    });

                    return (
                        <Table.Cell key={item.id + schemaKey} width={this.schema[schemaKey].width}>
                            {value}
                        </Table.Cell>
                    );
                }
                const transformer = listFieldTransformerRegistry.get(this.schema[schemaKey].type);
                const value = transformer.transform(
                    item[schemaKey],
                    this.schema[schemaKey].transformerTypeParameters,
                    item,
                );

                const indicators = [];

                if (index === 0 && published) {
                    indicators.push(
                        <div>
                            <span className={tableStyles.published} />
                        </div>,
                    );
                }

                return (
                    <Table.Cell key={item.id + schemaKey} width={this.schema[schemaKey].width}>
                        {value}
                        {indicators}
                    </Table.Cell>
                );
            });
    }

    getButtons = (item, icon) => {
        const { itemActionsProvider, onItemClick } = this.props;

        const { _permissions: { view: viewPermission = true } = {} } = item || {};

        const buttons = [];

        if (onItemClick) {
            buttons.push({
                disabled: !viewPermission,
                icon: icon ? 'su-pen' : undefined,
                onClick: onItemClick,
            });
        }

        if (itemActionsProvider) {
            buttons.push(...itemActionsProvider(item));
        }

        return buttons;
    };

    renderHeaderCells() {
        const { onSort, sortColumn, sortOrder } = this.props;
        const schemaKeys = Object.keys(this.schema);

        return schemaKeys
            .filter((key) => key !== 'published')
            .map((schemaKey) => {
                const columnSchema = this.schema[schemaKey];
                const label = columnSchema.label ? columnSchema.label : schemaKey;

                return (
                    <Table.HeaderCell
                        key={schemaKey}
                        name={schemaKey}
                        onClick={columnSchema.sortable ? onSort : undefined}
                        sortOrder={sortColumn === schemaKey ? sortOrder : undefined}
                        width={this.schema[schemaKey].width}
                    >
                        {label}
                    </Table.HeaderCell>
                );
            });
    }

    renderRows() {
        const { data, disabledIds, selections } = this.props;

        return data.map((item) => {
            return (
                <Table.Row
                    buttons={this.getButtons(item, true)}
                    disabled={disabledIds.includes(item.id)}
                    id={item.id}
                    key={item.id}
                    selected={selections.includes(item.id)}
                >
                    {this.renderCells(item)}
                </Table.Row>
            );
        });
    }

    render() {
        const {
            data,
            limit,
            loading,
            onAllSelectionChange,
            onItemSelectionChange,
            onLimitChange,
            onPageChange,
            paginated,
            adapterOptions: { show_header = true, skin = 'dark' } = {},
            page,
            pageCount,
        } = this.props;

        const table = (
            <Table
                buttons={this.getButtons()}
                onAllSelectionChange={onAllSelectionChange}
                onRowSelectionChange={onItemSelectionChange}
                selectMode={undefined}
                skin={skin}
                placeholderText={translate('sulu_admin.no_content')}
            >
                {!show_header ? null : <Table.Header>{this.renderHeaderCells()}</Table.Header>}
                <Table.Body>{this.renderRows()}</Table.Body>
            </Table>
        );

        if (!paginated || (page === 1 && data.length === 0)) {
            return table;
        }

        if (pageCount === undefined) {
            return table;
        }

        return (
            <Pagination
                currentLimit={limit}
                currentPage={page}
                loading={loading}
                onLimitChange={onLimitChange}
                onPageChange={onPageChange}
                totalPages={pageCount}
            >
                {table}
            </Pagination>
        );
    }
}

export default TableAdapter;
