import { action } from 'mobx';
import { translate } from 'sulu-admin-bundle/utils/Translator';
import AbstractListToolbarAction from 'sulu-admin-bundle/views/List/toolbarActions/AbstractListToolbarAction';

export default class AddToolbarAction extends AbstractListToolbarAction {
    getToolbarItemConfig() {
        const { label = 'sulu_admin.save' } = this.options;

        return {
            icon: 'su-en-plus-circle',
            label: translate(label),
            onClick: action(this.list.addItem),
            type: 'button',
        };
    }
}
