import { observer } from 'mobx-react';
import { action, computed, intercept, observable } from 'mobx';
import React, { Fragment } from 'react';
import equal from 'fast-deep-equal';
import classNames from 'classnames';
import jexl from 'jexl';
import Button from 'sulu-admin-bundle/components/Button';
import Dialog from 'sulu-admin-bundle/components/Dialog';
import Loader from 'sulu-admin-bundle/components/Loader';
import PermissionHint from 'sulu-admin-bundle/components/PermissionHint';
import userStore from 'sulu-admin-bundle/stores/userStore';
import SingleListOverlay from 'sulu-admin-bundle/containers/SingleListOverlay';
import { translate } from 'sulu-admin-bundle/utils';
import DeleteReferencedResourceDialog from 'sulu-admin-bundle/containers/DeleteReferencedResourceDialog';
import DeleteDependantResourcesDialog from 'sulu-admin-bundle/containers/DeleteDependantResourcesDialog';
import {
    ERROR_CODE_DEPENDANT_RESOURCES_FOUND,
    ERROR_CODE_REFERENCING_RESOURCES_FOUND,
} from 'sulu-admin-bundle/constants';
import listAdapterRegistry from 'sulu-admin-bundle/containers/List/registries/listAdapterRegistry';
import AdapterSwitch from 'sulu-admin-bundle/containers/List/AdapterSwitch';
import Search from 'sulu-admin-bundle/containers/List/Search';
import listStyles from 'sulu-admin-bundle/containers/List/list.scss';

const USER_SETTING_PREFIX = 'sulu_admin.list';
const USER_SETTING_ADAPTER = 'adapter';

@observer
class MediaList extends React.Component {
    static defaultProps = {
        actions: [],
        allowActivateForDisabledItems: true,
        copyable: true,
        deletable: true,
        disabled: false,
        disabledIds: [],
        filterable: true,
        movable: true,
        orderable: true,
        paginated: true,
        searchable: true,
        selectable: true,
        showColumnOptions: true,
    };

    @observable currentAdapterKey;
    @observable showCopyOverlay = false;
    @observable showDeleteDialog = false;
    @observable showMoveOverlay = false;
    @observable showDeleteSelectionDialog = false;
    @observable allowConflictDeletion = true;
    @observable showOrderDialog = false;
    @observable adapterOptionsOpen = false;
    @observable columnOptionsOpen = false;
    @observable referencingResourcesData = undefined;
    @observable dependantResourcesData = undefined;
    @observable movingRestrictedTarget = undefined;
    resolveCopy;
    resolveDelete;
    resolveMove;
    resolveOrder;
    moveId;
    adapterDisposer;

    static getAdapterSetting(listKey, userSettingsKey) {
        const key = [USER_SETTING_PREFIX, listKey, userSettingsKey, USER_SETTING_ADAPTER].join('.');

        return userStore.getPersistentSetting(key);
    }

    static setAdapterSetting(listKey, userSettingsKey, value) {
        const key = [USER_SETTING_PREFIX, listKey, userSettingsKey, USER_SETTING_ADAPTER].join('.');

        userStore.setPersistentSetting(key, value);
    }

    @computed get currentAdapter() {
        return listAdapterRegistry.get(this.currentAdapterKey);
    }

    @computed get currentAdapterOptions() {
        return listAdapterRegistry.getOptions(this.currentAdapterKey);
    }

    @computed get disabledIds() {
        const { disabledIds, itemDisabledCondition, store } = this.props;

        const disabledItems = itemDisabledCondition
            ? store.visibleItems.filter((item) => jexl.evalSync(itemDisabledCondition, item))
            : [];

        return [...disabledIds, ...disabledItems.map((item) => item.id)];
    }

    @computed get showColumnOptions() {
        return this.currentAdapter.hasColumnOptions && this.props.showColumnOptions;
    }

    constructor(props) {
        super(props);

        this.validateAdapters();

        const { store } = this.props;

        this.adapterDisposer = intercept(this, 'currentAdapterKey', (change) => {
            MediaList.setAdapterSetting(store.listKey, store.userSettingsKey, change.newValue);
            return change;
        });
    }

    componentDidUpdate(prevProps) {
        const { adapters, store, paginated } = this.props;
        if (!equal(adapters, prevProps.adapters)) {
            this.validateAdapters();
        }

        if (store !== prevProps.store) {
            store.updateLoadingStrategy(
                new this.currentAdapter.LoadingStrategy({
                    paginated: this.currentAdapter.paginatable && paginated,
                }),
            );
            store.updateStructureStrategy(new this.currentAdapter.StructureStrategy());
        }
    }

    validateAdapters() {
        const { adapters, store } = this.props;

        adapters.forEach((adapterName) => {
            if (!listAdapterRegistry.has(adapterName)) {
                throw new Error(
                    'ListAdapter with the name "' +
                        adapterName +
                        '" does not exist.' +
                        'Did you forget to add it to the "listAdapterRegistry"?',
                );
            }
        });

        if (!this.currentAdapterKey) {
            const adapterKey = MediaList.getAdapterSetting(store.listKey, store.userSettingsKey);
            this.setCurrentAdapterKey(adapterKey || this.props.adapters[0]);
        }
    }

    @action setCurrentAdapterKey = (adapter) => {
        this.currentAdapterKey = adapter;

        if (!(this.props.store.loadingStrategy instanceof this.currentAdapter.LoadingStrategy)) {
            this.props.store.updateLoadingStrategy(
                new this.currentAdapter.LoadingStrategy({
                    paginated: this.currentAdapter.paginatable && this.props.paginated,
                }),
            );
        }

        if (!(this.props.store.structureStrategy instanceof this.currentAdapter.StructureStrategy)) {
            this.props.store.updateStructureStrategy(new this.currentAdapter.StructureStrategy());
        }
    };

    @action requestSelectionDelete = (allowConflictDeletion = true) => {
        this.showDeleteSelectionDialog = true;
        this.allowConflictDeletion = allowConflictDeletion;
    };

    @action handleSelectionDeleteDialogConfirmClick = () => {
        this.props.store
            .deleteSelection()
            .then(
                action(() => {
                    this.showDeleteSelectionDialog = false;
                }),
            )
            .catch(this.handleDeleteResponseError);
    };

    @action handleSelectionDeleteDialogCancelClick = () => {
        this.showDeleteSelectionDialog = false;
    };

    @action handleRequestItemDelete = (id) => {
        this.showDeleteDialog = true;

        const deletePromise = new Promise((resolve) => (this.resolveDelete = resolve));
        deletePromise.then(
            action((response) => {
                if (!response.deleted) {
                    this.showDeleteDialog = false;
                    return response;
                }

                this.props.store
                    .delete(id)
                    .then(
                        action(() => {
                            this.showDeleteDialog = false;
                        }),
                    )
                    .catch(this.handleDeleteResponseError);

                return response;
            }),
        );

        return deletePromise;
    };

    @action closeAllDialogs = () => {
        this.showDeleteDialog = false;
        this.showDeleteSelectionDialog = false;
        this.referencingResourcesData = undefined;
        this.dependantResourcesData = undefined;
    };

    @action handleDeleteResponseError = (response) => {
        const { onDeleteError } = this.props;

        response.json().then(
            action((data) => {
                this.closeAllDialogs();

                if (response.status === 409 && data.code === ERROR_CODE_REFERENCING_RESOURCES_FOUND) {
                    this.referencingResourcesData = {
                        resource: data.resource,
                        referencingResources: data.referencingResources,
                        referencingResourcesCount: data.referencingResourcesCount,
                    };

                    const promise = new Promise((resolve) => (this.resolveDelete = resolve));

                    promise.then(
                        action((response) => {
                            if (!response.deleted) {
                                this.closeAllDialogs();

                                return response;
                            }

                            this.props.store
                                .delete(data.resource.id, { force: true })
                                .then(this.closeAllDialogs)
                                .catch(this.handleDeleteResponseError);
                        }),
                    );

                    return;
                }

                if (response.status === 409 && data.code === ERROR_CODE_DEPENDANT_RESOURCES_FOUND) {
                    this.dependantResourcesData = {
                        dependantResourceBatches: data.dependantResourceBatches,
                        dependantResourcesCount: data.dependantResourcesCount,
                        detail: data.detail,
                        title: data.title,
                    };

                    const promise = new Promise((resolve) => (this.resolveDelete = resolve));

                    promise.then(
                        action((response) => {
                            if (!response.deleted) {
                                this.closeAllDialogs();

                                return response;
                            }

                            this.props.store
                                .delete(data.resource.id)
                                .then(this.closeAllDialogs)
                                .catch(this.handleDeleteResponseError);
                        }),
                    );

                    return;
                }

                if (onDeleteError) {
                    onDeleteError(data);
                }
            }),
        );
    };

    @action handleDeleteDialogConfirmClick = () => {
        if (!this.resolveDelete) {
            throw new Error('The resolveDelete function is not set. This should not happen, and is likely a bug.');
        }

        this.resolveDelete({ deleted: true });
    };

    @action handleDeleteDialogCancelClick = () => {
        if (!this.resolveDelete) {
            throw new Error('The resolveDelete function is not set. This should not happen, and is likely a bug.');
        }

        this.resolveDelete({ deleted: false });
    };

    @action handleRequestItemMove = (id) => {
        this.moveId = id;
        this.showMoveOverlay = true;

        const movePromise = new Promise((resolve) => (this.resolveMove = resolve));
        movePromise.then(
            action((response) => {
                if (!response.moved || !response.parent) {
                    this.showMoveOverlay = false;
                    this.moveId = undefined;
                    return response;
                }

                if (!this.moveId) {
                    throw new Error('The moveId is not set. This should not happen and is likely a bug.');
                }

                this.props.store.move(this.moveId, response.parent.id).then(
                    action(() => {
                        this.moveId = undefined;
                        this.showMoveOverlay = false;
                    }),
                );

                return response;
            }),
        );

        return movePromise;
    };

    @action handleMoveOverlayConfirmClick = (parent) => {
        if (!this.moveId) {
            throw new Error('The moveId is not set. This should not happen and is likely a bug.');
        }

        const element = this.props.store.findById(this.moveId);

        if (!element) {
            throw new Error('The moveId does not refer to an element. This should not happen and is likely a bug.');
        }

        if (!element._hasPermissions && !parent._hasPermissions) {
            if (!this.resolveMove) {
                throw new Error('The resolveMove function is not set. This should not happen, and is likely a bug.');
            }

            this.resolveMove({ moved: true, parent });
        } else {
            this.movingRestrictedTarget = parent;
        }
    };

    @action handleMoveOverlayClose = () => {
        if (!this.resolveMove) {
            throw new Error('The resolveMove function is not set. This should not happen, and is likely a bug.');
        }

        this.resolveMove({ moved: false });
    };

    @action handleMovePermissionWarningConfirm = () => {
        if (!this.resolveMove) {
            throw new Error('The resolveMove function is not set. This should not happen, and is likely a bug.');
        }

        this.resolveMove({ moved: true, parent: this.movingRestrictedTarget });
        this.movingRestrictedTarget = undefined;
    };

    @action handleMovePermissionWarningCancel = () => {
        this.movingRestrictedTarget = undefined;
    };

    @action handleRequestItemCopy = (id) => {
        this.showCopyOverlay = true;

        const copyPromise = new Promise((resolve) => (this.resolveCopy = resolve));
        copyPromise.then(
            action((response) => {
                if (!response.copied) {
                    this.showCopyOverlay = false;
                    return response;
                }

                this.props.store.copy(id, response.parent.id, this.props?.onCopyFinished).then(
                    action(() => {
                        this.showCopyOverlay = false;
                    }),
                );

                return response;
            }),
        );

        return copyPromise;
    };

    @action handleCopyOverlayConfirmClick = (parent) => {
        if (!this.resolveCopy) {
            throw new Error('The resolveCopy function is not set. This should not happen, and is likely a bug.');
        }

        this.resolveCopy({ copied: true, parent });
    };

    @action handleCopyOverlayClose = () => {
        if (!this.resolveCopy) {
            throw new Error('The resolveCopy function is not set. This should not happen, and is likely a bug.');
        }

        this.resolveCopy({ copied: false });
    };

    @action handleRequestItemOrder = (id, position) => {
        this.showOrderDialog = true;

        const orderPromise = new Promise((resolve) => (this.resolveOrder = resolve));
        orderPromise.then(
            action((response) => {
                if (!response.ordered) {
                    this.showOrderDialog = false;
                    return response;
                }

                this.props.store.order(id, position).then(
                    action(() => {
                        this.showOrderDialog = false;
                    }),
                );

                return response;
            }),
        );

        return orderPromise;
    };

    @action handleOrderDialogConfirmClick = () => {
        if (!this.resolveOrder) {
            throw new Error('The resolveOrder function is not set. This should not happen, and is likely a bug.');
        }

        this.resolveOrder({ ordered: true });
    };

    @action handleOrderDialogCancelClick = () => {
        if (!this.resolveOrder) {
            throw new Error('The resolveOrder function is not set. This should not happen, and is likely a bug.');
        }

        this.resolveOrder({ ordered: false });
    };

    handlePageChange = (page) => {
        this.props.store.setPage(page);
    };

    handleLimitChange = (limit) => {
        this.props.store.setLimit(limit);
    };

    handleSort = (column, order) => {
        this.props.store.sort(column, order);
    };

    handleSearch = (search) => {
        this.props.store.search(search);
    };

    handleFilterChange = (filter) => {
        this.props.store.filter(filter);
    };

    handleItemSelectionChange = (id, selected) => {
        const { store } = this.props;
        const row = store.findById(id);

        if (!row) {
            return;
        }

        selected ? store.select(row) : store.deselect(row);
    };

    handleAllSelectionChange = (selected) => {
        const { store } = this.props;

        store.visibleItems.forEach((item) => {
            if (!this.disabledIds.includes(item.id)) {
                selected ? store.select(item) : store.deselect(item);
            }
        });
    };

    handleAdapterChange = (adapter) => {
        this.setCurrentAdapterKey(adapter);
    };

    handleItemActivate = (id) => {
        const { allowActivateForDisabledItems, store } = this.props;

        if (!allowActivateForDisabledItems && this.disabledIds.includes(id)) {
            return;
        }

        store.activate(id);
    };

    handleItemDeactivate = (id) => {
        this.props.store.deactivate(id);
    };

    @action handleAdapterOptionsButtonClick = () => {
        this.adapterOptionsOpen = !this.adapterOptionsOpen;
    };

    @action handleAdapterOptionsClose = () => {
        this.adapterOptionsOpen = false;
    };

    @action handleColumnOptionsOpen = () => {
        this.columnOptionsOpen = true;
    };

    @action handleColumnOptionsClose = () => {
        this.columnOptionsOpen = false;
    };

    @action handleColumnOptionsChange = (schema) => {
        this.columnOptionsOpen = false;
        this.props.store.changeUserSchema(schema);
    };

    renderDeleteReferencedResourceDialog() {
        if (!this.referencingResourcesData) {
            return null;
        }

        const { store } = this.props;

        return (
            <DeleteReferencedResourceDialog
                allowDeletion={this.allowConflictDeletion}
                confirmLoading={store.deleting}
                onCancel={this.handleDeleteDialogCancelClick}
                onConfirm={this.handleDeleteDialogConfirmClick}
                referencingResourcesData={this.referencingResourcesData}
            />
        );
    }

    @computed get deleteDependantResourcesDialogRequestOptions() {
        const { store } = this.props;

        return store.queryOptions;
    }

    renderDeleteDependantResourcesDialog() {
        if (!this.dependantResourcesData) {
            return null;
        }

        return (
            <DeleteDependantResourcesDialog
                dependantResourcesData={this.dependantResourcesData}
                onCancel={this.handleDeleteDialogCancelClick}
                onFinish={this.handleDeleteDialogConfirmClick}
                requestOptions={this.deleteDependantResourcesDialogRequestOptions}
            />
        );
    }

    render() {
        const {
            actions,
            adapters,
            copyable,
            deletable,
            disabled,
            header,
            itemActionsProvider,
            movable,
            onItemClick,
            onItemAdd,
            paginated,
            orderable,
            adapterOptions,
            selectable,
            store,
            toolbarClassName,
        } = this.props;

        const { filterableFields, loading, schemaLoading } = store;

        const Adapter = this.currentAdapter;

        const listClass = classNames(listStyles.list, {
            [listStyles.disabled]: disabled,
        });

        const toolbarClass = classNames(listStyles.toolbar, toolbarClassName);

        const searchable = this.props.searchable && Adapter.searchable;
        const filterable = this.props.filterable && filterableFields && Object.keys(filterableFields).length > 0;

        const hasToolbar = searchable || filterable || actions.length || this.showColumnOptions || adapters.length > 1;

        if (store.forbidden) {
            return <PermissionHint />;
        }

        return (
            <div className={listStyles.listContainer}>
                {header}
                {!schemaLoading && hasToolbar && (
                    <div className={toolbarClass}>
                        <div className={listStyles.toolbarLeft}>
                            {searchable && <Search onSearch={this.handleSearch} value={store.searchTerm.get()} />}
                        </div>
                        <div className={listStyles.toolbarRight}>
                            {actions.map((action, index) => {
                                const handleClick = action.onClick;

                                return (
                                    <Button
                                        disabled={action.disabled}
                                        icon={action.icon}
                                        key={index}
                                        onClick={handleClick}
                                        skin="icon"
                                    >
                                        {action.label}
                                    </Button>
                                );
                            })}
                            <AdapterSwitch
                                adapters={adapters}
                                currentAdapter={this.currentAdapterKey}
                                onAdapterChange={this.handleAdapterChange}
                            />
                        </div>
                    </div>
                )}
                <div className={listClass}>
                    {loading && store.pageCount === 0 ? (
                        <Loader className={listStyles.loader} />
                    ) : (
                        <Adapter
                            active={store.active.get()}
                            activeItems={store.activeItems}
                            adapterOptions={adapterOptions ? adapterOptions[this.currentAdapterKey] : undefined}
                            data={store.data}
                            disabledIds={this.disabledIds}
                            itemActionsProvider={itemActionsProvider}
                            limit={store.limit.get()}
                            loading={loading}
                            onAllSelectionChange={selectable ? this.handleAllSelectionChange : undefined}
                            onItemActivate={this.handleItemActivate}
                            onItemAdd={onItemAdd}
                            onItemClick={onItemClick}
                            onItemDeactivate={this.handleItemDeactivate}
                            onItemSelectionChange={selectable ? this.handleItemSelectionChange : undefined}
                            onLimitChange={this.handleLimitChange}
                            onPageChange={this.handlePageChange}
                            onRequestItemCopy={copyable ? this.handleRequestItemCopy : undefined}
                            onRequestItemDelete={deletable ? this.handleRequestItemDelete : undefined}
                            onRequestItemMove={movable ? this.handleRequestItemMove : undefined}
                            onRequestItemOrder={orderable ? this.handleRequestItemOrder : undefined}
                            onSort={this.handleSort}
                            options={this.currentAdapterOptions}
                            page={store.getPage()}
                            pageCount={store.pageCount}
                            paginated={paginated}
                            schema={store.userSchema}
                            selections={store.selectionIds}
                            sortColumn={store.sortColumn.get()}
                            sortOrder={store.sortOrder.get()}
                        />
                    )}
                </div>
                <Dialog
                    cancelText={translate('sulu_admin.cancel')}
                    confirmLoading={store.deletingSelection}
                    confirmText={translate('sulu_admin.ok')}
                    onCancel={this.handleSelectionDeleteDialogCancelClick}
                    onConfirm={this.handleSelectionDeleteDialogConfirmClick}
                    open={this.showDeleteSelectionDialog}
                    title={translate('sulu_admin.delete_warning_title')}
                >
                    {translate('sulu_admin.delete_selection_warning_text', { count: store.selections.length })}
                </Dialog>
                {deletable && (
                    <Fragment>
                        <Dialog
                            cancelText={translate('sulu_admin.cancel')}
                            confirmLoading={store.deleting}
                            confirmText={translate('sulu_admin.ok')}
                            onCancel={this.handleDeleteDialogCancelClick}
                            onConfirm={this.handleDeleteDialogConfirmClick}
                            open={this.showDeleteDialog}
                            title={translate('sulu_admin.delete_warning_title')}
                        >
                            {translate('sulu_admin.delete_warning_text')}
                        </Dialog>
                        {this.renderDeleteReferencedResourceDialog()}
                        {this.renderDeleteDependantResourcesDialog()}
                    </Fragment>
                )}
                {movable && (
                    <Fragment>
                        <SingleListOverlay
                            adapter={adapters[0]}
                            allowActivateForDisabledItems={false}
                            clearSelectionOnClose={true}
                            confirmLoading={store.movingSelection || store.moving}
                            disabledIds={this.moveId ? [this.moveId] : []}
                            listKey={store.listKey}
                            locale={store.observableOptions.locale}
                            metadataOptions={store.metadataOptions}
                            onClose={this.handleMoveOverlayClose}
                            onConfirm={this.handleMoveOverlayConfirmClick}
                            open={this.showMoveOverlay}
                            options={store.options}
                            reloadOnOpen={true}
                            resourceKey={store.resourceKey}
                            title={translate('sulu_admin.move_copy_overlay_title')}
                        />
                        <Dialog
                            cancelText={translate('sulu_admin.cancel')}
                            confirmText={translate('sulu_admin.confirm')}
                            onCancel={this.handleMovePermissionWarningCancel}
                            onConfirm={this.handleMovePermissionWarningConfirm}
                            open={!!this.movingRestrictedTarget}
                            title={translate('sulu_security.move_permission_title')}
                        >
                            {translate('sulu_security.move_permission_warning')}
                        </Dialog>
                    </Fragment>
                )}
                {copyable && (
                    <SingleListOverlay
                        adapter={adapters[0]}
                        clearSelectionOnClose={true}
                        confirmLoading={store.copying}
                        listKey={store.listKey}
                        locale={store.observableOptions.locale}
                        metadataOptions={store.metadataOptions}
                        onClose={this.handleCopyOverlayClose}
                        onConfirm={this.handleCopyOverlayConfirmClick}
                        open={this.showCopyOverlay}
                        reloadOnOpen={true}
                        resourceKey={store.resourceKey}
                        title={translate('sulu_admin.move_copy_overlay_title')}
                    />
                )}
                {orderable && (
                    <Dialog
                        cancelText={translate('sulu_admin.cancel')}
                        confirmLoading={store.ordering}
                        confirmText={translate('sulu_admin.ok')}
                        onCancel={this.handleOrderDialogCancelClick}
                        onConfirm={this.handleOrderDialogConfirmClick}
                        open={this.showOrderDialog}
                        title={translate('sulu_admin.order_warning_title')}
                    >
                        {translate('sulu_admin.order_warning_text')}
                    </Dialog>
                )}
            </div>
        );
    }
}

export default MediaList;
