import { action, observable } from 'mobx';
import { ResourceRequester } from 'sulu-admin-bundle/services';

const RESOURCE_KEY = 'media_formats';

export default class MediaFormatStore {
    id;
    locale;
    @observable mediaFormats;
    @observable loading;
    @observable saving;

    constructor(id, locale) {
        this.id = id;
        this.locale = locale;
        this.loading = true;
        ResourceRequester.getList(RESOURCE_KEY, { id, locale }).then(
            action((response) => {
                this.loading = false;
                this.mediaFormats = response;
            }),
        );
    }

    getFormatOptions(formatKey) {
        if (!this.mediaFormats) {
            return undefined;
        }

        return this.mediaFormats[formatKey];
    }

    @action updateFormatOptions(options) {
        this.saving = true;

        return ResourceRequester.patch(RESOURCE_KEY, options, { id: this.id, locale: this.locale }).then(
            action((response) => {
                this.saving = false;
                const mediaFormats = { ...this.mediaFormats, ...response };
                this.mediaFormats = Object.keys(mediaFormats).reduce((newMediaFormats, mediaFormatKey) => {
                    const mediaFormat = mediaFormats[mediaFormatKey];

                    if (Object.keys(mediaFormat).length === 0) {
                        return newMediaFormats;
                    }

                    newMediaFormats[mediaFormatKey] = mediaFormat;

                    return newMediaFormats;
                }, {});
            }),
        );
    }
}
