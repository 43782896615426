import React, { Fragment } from 'react';
import { action, computed, observable } from 'mobx';
import jexl from 'jexl';
import DeleteDependantResourcesDialog from 'sulu-admin-bundle/containers/DeleteDependantResourcesDialog';
import DeleteReferencedResourceDialog from 'sulu-admin-bundle/containers/DeleteReferencedResourceDialog';
import { translate } from 'sulu-admin-bundle/utils';
import {
    ERROR_CODE_DEPENDANT_RESOURCES_FOUND,
    ERROR_CODE_REFERENCING_RESOURCES_FOUND,
} from 'sulu-admin-bundle/constants';
import { AbstractFormToolbarAction } from 'sulu-admin-bundle/views';
import styles from './toolbar.scss';
import Dialog from '../../components/Dialog';
import snackbarStore from 'sulu-admin-bundle/stores/snackbarStore';

export default class DeleteToolbarAction extends AbstractFormToolbarAction {
    @observable showDialog = false;
    @observable referencingResourcesData = undefined;
    @observable dependantResourcesData = undefined;

    @computed get allowConflictDeletion() {
        const { allow_conflict_deletion: allowConflictDeletion = true } = this.options;

        return !!allowConflictDeletion;
    }

    constructor(resourceFormStore, form, router, locales, options, parentResourceStore) {
        const {
            display_condition: displayCondition,
            visible_condition: visibleCondition,
            delete_locale: deleteLocale = false,
        } = options;

        if (displayCondition) {
            if (!visibleCondition) {
                options.visible_condition = displayCondition;
            }
        }

        if (typeof deleteLocale !== 'boolean') {
            throw new Error('The "delete_locale" option must be a boolean, but received ' + typeof deleteLocale + '!');
        }

        super(resourceFormStore, form, router, locales, options, parentResourceStore);
    }

    handleDeleteReferencedResourcesDialogCancel = () => {
        this.closeDeleteReferencedResourceDialog();
    };

    @action handleDeleteReferencedResourcesDialogConfirm = () => {
        this.delete(true);
    };

    @action closeDeleteReferencedResourceDialog = () => {
        this.referencingResourcesData = undefined;
    };

    renderDeleteReferencedResourceDialog() {
        if (!this.referencingResourcesData) {
            return null;
        }

        return (
            <DeleteReferencedResourceDialog
                allowDeletion={this.allowConflictDeletion}
                confirmLoading={this.resourceFormStore.deleting}
                onCancel={this.handleDeleteReferencedResourcesDialogCancel}
                onConfirm={this.handleDeleteReferencedResourcesDialogConfirm}
                referencingResourcesData={this.referencingResourcesData}
            />
        );
    }

    handleDeleteDependantResourcesDialogFinish = () => {
        this.delete();
    };

    handleDeleteDependantResourcesDialogCancel = () => {
        this.closeDeleteDependantResourcesDialog();
    };

    @action closeDeleteDependantResourcesDialog = () => {
        this.dependantResourcesData = undefined;
    };

    @computed get deleteDependantResourcesDialogRequestOptions() {
        const { locale, options: resourceFormStoreOptions = {} } = this.resourceFormStore;

        const options = resourceFormStoreOptions;

        if (locale) {
            options.locale = locale.get();
        }

        return options;
    }

    renderDeleteDependantResourcesDialog() {
        if (!this.dependantResourcesData) {
            return null;
        }

        return (
            <DeleteDependantResourcesDialog
                dependantResourcesData={this.dependantResourcesData}
                onCancel={this.handleDeleteDependantResourcesDialogCancel}
                onFinish={this.handleDeleteDependantResourcesDialogFinish}
                requestOptions={this.deleteDependantResourcesDialogRequestOptions}
            />
        );
    }

    handleDialogCancel = () => {
        this.closeDialog();
    };

    handleDialogConfirm = () => {
        this.delete();
    };

    @action closeDialog = () => {
        this.showDialog = false;
    };

    renderDialog(postfix) {
        const { formKey } = this.resourceFormStore;
        let title = translate('sulu_admin.delete_warning_title');

        switch (formKey) {
            case 'article_details_edit':
                title = translate('sulu_admin.article.delete_warning_title');
                break;

            case 'article_tag_details_edit':
                title = translate('sulu_admin.article_tag.delete_warning_title');
                break;
            case 'career_details_edit':
                title = translate('sulu_admin.career.delete_warning_title');
                break;
            case 'employee_details_edit':
                title = translate('sulu_admin.employee.delete_warning_title');
                break;
            case 'department_details_edit':
                title = translate('sulu_admin.department.delete_warning_title');
                break;
            case 'investment_details_edit':
                title = translate('sulu_admin.investment.delete_warning_title');
                break;
            case 'investment_tag_details_edit':
                title = translate('sulu_admin.investment_tag.delete_warning_title');
                break;
            case 'project_details_edit':
                title = translate('sulu_admin.project.delete_warning_title');
                break;
            case 'project_tag_details_edit':
                title = translate('sulu_admin.project_tag.delete_warning_title');
                break;
            case 'energon_user_edit':
                title = translate('sulu_admin.energon_user.delete_warning_title');
                break;

            case 'energon_media_details':
                title = translate('sulu_admin.delete_media_warning_title');
                break;
            default:
                break;
        }

        return (
            <Dialog
                className="deleteDialog"
                cancelText={translate('sulu_admin.cancel')}
                confirmLoading={this.resourceFormStore.deleting}
                confirmText={translate('sulu_security.delete')}
                onCancel={this.handleDialogCancel}
                onConfirm={this.handleDialogConfirm}
                open={this.showDialog}
                title={title}
                confirmButtonClassName={styles.confirmRemoveButton}
            >
                {translate('sulu_admin.delete' + postfix + '_warning_text')}
            </Dialog>
        );
    }

    getNode() {
        const { delete_locale: deleteLocale = false } = this.options;
        const postfix = deleteLocale ? '_locale' : '';

        return (
            <Fragment key={'sulu_admin.delete' + postfix}>
                {this.renderDialog(postfix)}
                {this.renderDeleteReferencedResourceDialog()}
                {this.renderDeleteDependantResourcesDialog()}
            </Fragment>
        );
    }

    getToolbarItemConfig() {
        if (!this.resourceFormStore.data.canChange) {
            if (this.options?.force_allow_delete !== true) {
                return null;
            }
        }

        if (this.resourceFormStore.data.disableDelete === true) {
            return null;
        }

        const {
            visible_condition: visibleCondition,
            delete_locale: deleteLocale = false,
            label = 'sulu_admin.delete',
        } = this.options;

        const { id } = this.resourceFormStore;

        const visibleConditionFulfilled = !visibleCondition || jexl.evalSync(visibleCondition, this.conditionData);
        const isDisabled =
            !id || (deleteLocale && jexl.evalSync('contentLocales && contentLocales|length == 1', this.conditionData));

        if (visibleConditionFulfilled) {
            return {
                disabled: !!isDisabled,
                icon: 'su-trash-alt',
                label: translate(label),
                onClick: action(() => {
                    this.showDialog = true;
                }),
                type: 'button',
            };
        }
    }

    navigateBack = () => {
        const { attributes, route } = this.router;
        const { backView } = route.options;
        const { locale } = this.resourceFormStore;

        const { router_attributes_to_back_view: routerAttributesToBackView } = this.options;

        const backViewAttributes = { locale: locale ? locale.get() : undefined };
        if (routerAttributesToBackView) {
            if (typeof routerAttributesToBackView !== 'object') {
                throw new Error('The "router_attributes_to_back_view" option must be an object!');
            }

            Object.keys(routerAttributesToBackView).forEach((key) => {
                const attributeKey = routerAttributesToBackView[key];
                const attributeName = isNaN(key) ? key : routerAttributesToBackView[key];

                if (typeof attributeKey !== 'string' || typeof attributeName !== 'string') {
                    throw new Error('The value of the "router_attributes_to_back_view" option must be a string!');
                }

                backViewAttributes[attributeKey] = attributes[attributeName];
            });
        }

        this.router.restore(backView, backViewAttributes);
    };

    @action delete = (force = false) => {
        const { delete_locale: deleteLocale = false } = this.options;

        const { resourceKey } = this.resourceFormStore;

        const options = { deleteLocale };

        if (force) {
            options.force = true;
        }

        return this.resourceFormStore
            .delete(options)
            .then(() => {
                this.closeDialog();
                this.closeDeleteDependantResourcesDialog();
                this.closeDeleteReferencedResourceDialog();

                let successMessage;

                switch (resourceKey) {
                    case 'articles':
                        successMessage = translate('article_successfully_removed');
                        break;
                    case 'article_tags':
                        successMessage = translate('article_tag_successfully_removed');
                        break;
                    case 'careers':
                        successMessage = translate('career_successfully_removed');
                        break;
                    case 'employees':
                        successMessage = translate('employee_successfully_removed');
                        break;
                    case 'departments':
                        successMessage = translate('department_successfully_removed');
                        break;
                    case 'investments':
                        successMessage = translate('investment_successfully_removed');
                        break;
                    case 'investment_tags':
                        successMessage = translate('investment_tag_successfully_removed');
                        break;
                    case 'projects':
                        successMessage = translate('project_successfully_removed');
                        break;
                    case 'project_tags':
                        successMessage = translate('project_tag_successfully_removed');
                        break;
                    case 'energon_users':
                        successMessage = translate('energon_user_successfully_removed');
                        break;
                    default:
                        break;
                }

                if (successMessage) {
                    snackbarStore.add(
                        {
                            type: 'success',
                            text: successMessage,
                        },
                        2500,
                    );
                }

                this.navigateBack();
            })
            .catch(
                action((response) => {
                    response.json().then(
                        action((data) => {
                            this.closeDialog();
                            this.closeDeleteDependantResourcesDialog();
                            this.closeDeleteReferencedResourceDialog();

                            if (response.status === 409 && data.code === ERROR_CODE_DEPENDANT_RESOURCES_FOUND) {
                                this.dependantResourcesData = {
                                    dependantResourceBatches: data.dependantResourceBatches,
                                    dependantResourcesCount: data.dependantResourcesCount,
                                    detail: data.detail,
                                    title: data.title,
                                };

                                return;
                            }

                            if (response.status === 409 && data.code === ERROR_CODE_REFERENCING_RESOURCES_FOUND) {
                                this.referencingResourcesData = {
                                    resource: data.resource,
                                    referencingResources: data.referencingResources,
                                    referencingResourcesCount: data.referencingResourcesCount,
                                };

                                return;
                            }

                            const error =
                                data.detail || data.title || translate('sulu_admin.unexpected_delete_server_error');

                            if (error) {
                                this.form.errors.push(error);
                            }
                        }),
                    );
                }),
            );
    };
}
