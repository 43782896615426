import React, { Fragment } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { action, computed, observable } from 'mobx';
import Icon from 'sulu-admin-bundle/components/Icon';
import SingleSelect from 'sulu-admin-bundle/components/SingleSelect';
import { translate } from 'sulu-admin-bundle/utils';
import blockStyles from 'sulu-admin-bundle/components/Block/block.scss';
import ActionPopover from 'sulu-admin-bundle/components/Block/ActionPopover';

@observer
class Block extends React.Component {
    static defaultProps = {
        actions: [],
        expanded: false,
        selected: false,
    };

    @observable actionsButtonRef;
    @observable showActionsPopover = false;

    @computed get actions() {
        const { onRemove, actions } = this.props;

        if (onRemove) {
            return [
                ...actions,
                {
                    type: 'button',
                    icon: 'su-trash-alt',
                    label: translate('sulu_admin.delete'),
                    onClick: onRemove,
                },
            ];
        }

        return actions;
    }

    @action setActionsButtonRef = (ref) => {
        this.actionsButtonRef = ref;
    };

    @action handleActionsButtonClick = () => {
        this.showActionsPopover = true;
    };

    @action handleActionsPopoverClose = () => {
        this.showActionsPopover = false;
    };

    handleCollapse = () => {
        const { expanded, onCollapse } = this.props;
        if (expanded && onCollapse) {
            onCollapse();
        }
    };

    handleExpand = () => {
        const { expanded, onExpand } = this.props;
        if (!expanded && onExpand) {
            onExpand();
        }
    };

    handleTypeChange = (type) => {
        const { onTypeChange } = this.props;

        if (onTypeChange) {
            onTypeChange(type);
        }
    };

    render() {
        const { activeType, children, handle, icons, onCollapse, onExpand, onSettingsClick, selected, types } =
            this.props;

        const expanded = this.props.expanded || (!onCollapse && !onExpand);

        const blockClass = classNames(blockStyles.block, {
            [blockStyles.expanded]: expanded,
            [blockStyles.selected]: selected,
        });

        return (
            <section className={blockClass} onClick={this.handleExpand} role="switch">
                {handle && <div className={blockStyles.handle}>{handle}</div>}
                <div className={blockStyles.content}>
                    <header className={blockStyles.header}>
                        {expanded ? (
                            <Fragment>
                                {types && Object.keys(types).length > 1 && (
                                    <div className={blockStyles.types}>
                                        <SingleSelect onChange={this.handleTypeChange} value={activeType}>
                                            {Object.keys(types).map((key) => (
                                                <SingleSelect.Option key={key} value={key}>
                                                    {types[key]}
                                                </SingleSelect.Option>
                                            ))}
                                        </SingleSelect>
                                    </div>
                                )}
                                {icons && (
                                    <div className={blockStyles.icons}>
                                        {icons.map((icon) => (
                                            <Icon key={icon} name={icon} />
                                        ))}
                                    </div>
                                )}
                                <div className={blockStyles.iconButtons}>
                                    {this.actions.length > 0 && (
                                        <button
                                            onClick={this.handleActionsButtonClick}
                                            ref={this.setActionsButtonRef}
                                            type="button"
                                        >
                                            <Icon name="su-cog" />
                                        </button>
                                    )}
                                    {onSettingsClick && (
                                        <button onClick={onSettingsClick} type="button">
                                            <Icon name="su-cog" />
                                        </button>
                                    )}
                                    {onCollapse && onExpand && (
                                        <button onClick={this.handleCollapse} type="button">
                                            <Icon name="su-collapse-vertical" />
                                        </button>
                                    )}
                                </div>
                                <ActionPopover
                                    actions={this.actions}
                                    anchorElement={this.actionsButtonRef}
                                    onClose={this.handleActionsPopoverClose}
                                    open={this.showActionsPopover}
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                {icons && (
                                    <div className={blockStyles.icons}>
                                        {icons.map((icon) => (
                                            <Icon key={icon} name={icon} />
                                        ))}
                                    </div>
                                )}
                                {types && activeType && <div className={blockStyles.type}>{types[activeType]}</div>}
                                {onCollapse && onExpand && <Icon name="su-expand-vertical" />}
                            </Fragment>
                        )}
                    </header>
                    <article className={blockStyles.children}>{children}</article>
                </div>
            </section>
        );
    }
}

export default Block;
