import { computed } from 'mobx';
import { ResourceStore } from 'sulu-admin-bundle/stores';

const COLLECTIONS_RESOURCE_KEY = 'collections';

export default class CollectionStore {
    collectionId;
    locale;
    resourceStore;

    constructor(collectionId, locale) {
        this.collectionId = collectionId;
        this.locale = locale;
        this.resourceStore = new ResourceStore(
            COLLECTIONS_RESOURCE_KEY,
            collectionId,
            {
                locale,
            },
            {
                depth: 1,
                breadcrumb: true,
                parent: true,
            },
        );
    }

    destroy() {
        this.resourceStore.destroy();
    }

    @computed get loading() {
        return this.resourceStore ? this.resourceStore.loading : false;
    }

    @computed get id() {
        return this.resourceStore.id;
    }

    @computed get locked() {
        if (this.loading) {
            return false;
        }

        return this.resourceStore.data.locked;
    }

    @computed get permissions() {
        if (this.resourceStore.loading || !this.resourceStore.id) {
            return {};
        }

        return this.resourceStore.data._permissions || {};
    }

    @computed get parentId() {
        const { data } = this.resourceStore;

        if (!data._embedded) {
            return null;
        }

        const {
            _embedded: { parent },
        } = data;

        return parent ? parent.id : null;
    }
}
