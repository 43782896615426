import { observer } from 'mobx-react';
import { action, observable, autorun, computed } from 'mobx';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import NavigationSidebar from '../navigation/NavigationSidebar';
import Backdrop from 'sulu-admin-bundle/components/Backdrop';
import Loader from 'sulu-admin-bundle/components/Loader';
import initializer from 'sulu-admin-bundle/services/initializer';
import userStore from 'sulu-admin-bundle/stores/userStore';
import Login from 'sulu-admin-bundle/containers/Login';
import ProfileFormOverlay from 'sulu-admin-bundle/containers/ProfileFormOverlay';
import Sidebar, { sidebarStore } from 'sulu-admin-bundle/containers/Sidebar';
import ViewRenderer from 'sulu-admin-bundle/containers/ViewRenderer';
import 'sulu-admin-bundle/containers/Application/global.scss';
import SnackbarContainer from 'sulu-admin-bundle/components/SnackbarContainer';
import snackbarStore from 'sulu-admin-bundle/stores/snackbarStore';
import applicationStyles from './application.scss';
import Toolbar from '../navigation/Toolbar';
import Snackbar from '../../components/Snackbar';

const NAVIGATION_PINNED_SETTING_KEY = 'sulu_admin.application.navigation_pinned';

@observer
class Application extends React.Component {
    @observable openedProfileFormOverlay = false;

    @action openProfileFormOverlay() {
        this.openedProfileFormOverlay = true;
    }

    @action closeProfileFormOverlay() {
        this.openedProfileFormOverlay = false;
    }

    @observable navigationState;

    @computed get navigationPinned() {
        return this.navigationState === 'pinned';
    }

    @computed get navigationVisible() {
        return this.navigationPinned || this.navigationState === 'visible';
    }

    @action setNavigationState(state) {
        this.navigationState = state;
    }

    set navigationPinned(value) {
        this.setNavigationState(value ? 'pinned' : 'hidden');
    }

    set navigationVisible(value) {
        if (this.navigationPinned) {
            return;
        }

        this.setNavigationState(value ? 'visible' : 'hidden');
    }

    navigationPinnedDisposer;

    constructor(props) {
        super(props);

        this.navigationPinnedDisposer = autorun(
            () => (this.navigationPinned = userStore.getPersistentSetting(NAVIGATION_PINNED_SETTING_KEY)),
        );
    }

    componentWillUnmount() {
        this.navigationPinnedDisposer();
    }

    toggleNavigation() {
        this.navigationVisible = !this.navigationVisible;
    }

    toggleNavigationPinned() {
        this.navigationPinned = !this.navigationPinned;
        userStore.setPersistentSetting(NAVIGATION_PINNED_SETTING_KEY, this.navigationPinned);
    }

    openNavigation = () => {
        this.navigationPinned = true;
        userStore.setPersistentSetting(NAVIGATION_PINNED_SETTING_KEY, this.navigationPinned);
        this.navigationVisible = true;
    };

    closeNavigation = () => {
        this.navigationPinned = false;
        userStore.setPersistentSetting(NAVIGATION_PINNED_SETTING_KEY, this.navigationPinned);
        this.navigationVisible = false;
    };

    handlePinToggle = () => {
        this.toggleNavigationPinned();
    };

    handleNavigate = () => {
        if (!this.navigationPinned) {
            this.toggleNavigation();
        }
    };

    handleLoginSuccess = () => {
        this.props.router.reload();
    };

    handleLogout = () => {
        userStore.logout().then(() => {
            if (this.navigationVisible && !this.navigationPinned) {
                this.toggleNavigation();
            }
        });
    };

    handleProfileOverlayClose = () => {
        this.closeProfileFormOverlay();
    };

    handleProfileEditClick = () => {
        this.openProfileFormOverlay();
    };

    render() {
        const { appVersion, router, suluVersion } = this.props;
        const { loggedIn } = userStore;

        const rootClass = classNames(applicationStyles.root, {
            [applicationStyles.visible]: loggedIn,
            [applicationStyles.navigationVisible]: this.navigationVisible,
            [applicationStyles.navigationPinned]: this.navigationPinned,
        });

        const sidebarClass = classNames(applicationStyles.sidebar, {
            [applicationStyles[sidebarStore.size]]: sidebarStore.size,
        });

        const snackbarClass = classNames(applicationStyles.snackbar, {
            [applicationStyles.isNavigationVisible]: this.navigationVisible,
            [applicationStyles.isNavigationPinned]: this.navigationPinned,
            [applicationStyles[sidebarStore.size]]: sidebarStore.size,
        });

        const contentClass = classNames(applicationStyles.content, {
            [applicationStyles.withSidebar]: sidebarStore.view,
            [applicationStyles.withPinnedNavigation]: this.navigationPinned,
        });

        return (
            <Fragment>
                {!loggedIn && (
                    <Login
                        backLink="/"
                        initialized={!initializer.loading && !!initializer.initializedTranslationsLocale}
                        onLoginSuccess={this.handleLoginSuccess}
                        router={router}
                    />
                )}
                {initializer.initialized && initializer.initializedTranslationsLocale ? (
                    <Fragment>
                        <div className={rootClass}>
                            <nav className={applicationStyles.navigation}>
                                <NavigationSidebar
                                    appVersion={appVersion}
                                    onLogout={this.handleLogout}
                                    onNavigate={this.handleNavigate}
                                    closeNavigation={this.closeNavigation}
                                    onProfileClick={this.handleProfileEditClick}
                                    pinned={this.navigationPinned}
                                    router={router}
                                    suluVersion={suluVersion}
                                />
                            </nav>
                            <div className={contentClass}>
                                <main className={applicationStyles.main}>
                                    <div className={applicationStyles.viewContainer}>
                                        {router.route && <ViewRenderer router={router} />}
                                    </div>
                                    <header>
                                        <Toolbar
                                            navigationOpen={this.navigationVisible}
                                            onNavigationButtonClick={
                                                this.navigationPinned ? undefined : this.openNavigation
                                            }
                                            router={router}
                                            shouldShowPreviewButton={sidebarStore.size === 'hidden-preview'}
                                        />
                                    </header>
                                </main>
                                <Sidebar className={sidebarClass} />
                                {this.navigationVisible && !this.navigationPinned && (
                                    <Backdrop fixed={false} onClick={this.openNavigation} visible={false} />
                                )}
                            </div>
                        </div>
                        <ProfileFormOverlay
                            onClose={this.handleProfileOverlayClose}
                            open={this.openedProfileFormOverlay}
                        />
                        {snackbarStore.messages.length ? (
                            <SnackbarContainer className={snackbarClass}>
                                {snackbarStore.messages.map((message, index) => {
                                    return (
                                        <Snackbar
                                            icon={message.icon}
                                            key={index}
                                            message={<strong>{message.text}</strong>}
                                            skin="floating"
                                            type={message.type}
                                        />
                                    );
                                })}
                            </SnackbarContainer>
                        ) : null}
                    </Fragment>
                ) : (
                    <div className={applicationStyles.loader}>
                        <Loader />
                    </div>
                )}
            </Fragment>
        );
    }
}

export default Application;
