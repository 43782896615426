import React from 'react';
import { Renderer } from 'sulu-admin-bundle/containers';

export default class FieldRenderer extends React.Component {
    static defaultProps = {
        showAllErrors: false,
    };

    handleChange = (name, value) => {
        const { index, onChange } = this.props;
        onChange(index, name, value);
    };

    render() {
        const {
            data,
            dataPath,
            errors,
            formInspector,
            onFieldFinish,
            onSuccess,
            router,
            schema,
            schemaPath,
            showAllErrors,
            value,
        } = this.props;

        return (
            <Renderer
                data={data}
                dataPath={dataPath}
                errors={errors}
                formInspector={formInspector}
                onChange={this.handleChange}
                onFieldFinish={onFieldFinish}
                onSuccess={onSuccess}
                router={router}
                schema={schema}
                schemaPath={schemaPath}
                showAllErrors={showAllErrors}
                value={value}
            />
        );
    }
}
