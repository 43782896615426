import { action, computed, observable, toJS } from 'mobx';
import { Requester } from 'sulu-admin-bundle/services';
import { buildQueryString, transformDateForUrl } from 'sulu-admin-bundle/utils';

const generateRoute = (name, options) => {
    return PreviewStore.endpoints[name] + buildQueryString(options);
};

export default class PreviewStore {
    static endpoints = {};

    resourceKey;
    id;
    @observable locale;
    @observable webspace;
    @observable segment;
    @observable targetGroup = -1;
    @observable dateTime = undefined;

    @observable token;

    constructor(resourceKey, id, locale, webspace, segment) {
        if (typeof locale !== 'string') {
            locale = toJS(locale);
        }
        this.resourceKey = resourceKey;
        this.id = id;
        this.locale = locale;
        this.webspace = webspace;
        this.segment = segment;
    }

    @computed get starting() {
        return !this.token;
    }

    @computed get renderRoute() {
        return generateRoute('render', {
            webspaceKey: this.webspace,
            segmentKey: this.segment,
            provider: this.resourceKey,
            id: this.id,
            locale: this.locale,
            token: this.token,
            targetGroupId: this.targetGroup,
            dateTime: this.dateTime && transformDateForUrl(this.dateTime),
        });
    }

    @action setToken = (token) => {
        this.token = token;
    };

    @action setWebspace = (webspace) => {
        this.webspace = webspace;
    };

    @action setTargetGroup = (targetGroup) => {
        this.targetGroup = targetGroup;
    };

    @action setSegment = (segment) => {
        this.segment = segment;
    };

    @action setDateTime = (dateTime) => {
        this.dateTime = dateTime;
    };

    start() {
        const route = generateRoute('start', {
            provider: this.resourceKey,
            id: this.id,
            locale: this.locale,
        });

        return Requester.post(route).then((response) => {
            this.setToken(response.token);
        });
    }

    @action restart(locale) {
        return this.stop().then(() => {
            if (locale) {
                this.locale = locale;
            }

            return this.start();
        });
    }

    stop() {
        const route = generateRoute('stop', { token: this.token });

        return Requester.post(route).then(() => this.setToken(null));
    }
}
