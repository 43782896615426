import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Form, resourceFormStoreFactory } from 'sulu-admin-bundle/containers';
import { translate } from 'sulu-admin-bundle/utils';
import { Dialog, Overlay } from 'sulu-admin-bundle/components';
import collectionFormOverlayStyles from './collectionFormOverlay.scss';

const FORM_KEY = 'collection_details';

@observer
class CollectionFormOverlay extends React.Component {
    formRef;
    @observable title;
    @observable formStore;

    constructor(props) {
        super(props);

        const { resourceStore } = this.props;
        this.formStore = resourceFormStoreFactory.createFromResourceStore(resourceStore, FORM_KEY);
    }

    @action componentDidUpdate(prevProps) {
        const { operationType } = this.props;

        if (operationType) {
            this.title =
                operationType === 'create'
                    ? translate('sulu_media.add_collection')
                    : translate('sulu_media.edit_collection');
        }

        if (this.props.resourceStore !== prevProps.resourceStore) {
            this.formStore.destroy();
            this.formStore = resourceFormStoreFactory.createFromResourceStore(this.props.resourceStore, FORM_KEY);
        }
    }

    componentWillUnmount() {
        this.formStore.destroy();
    }

    setFormRef = (formRef) => {
        this.formRef = formRef;
    };

    handleConfirm = () => {
        if (this.formRef) {
            this.formRef.submit();
        }
    };

    handleClose = () => {
        this.props.onClose();
    };

    handleSubmit = () => {
        const { onConfirm, resourceStore } = this.props;
        onConfirm(resourceStore);
    };

    render() {
        const { operationType, overlayType, resourceStore } = this.props;
        const open = operationType === 'create' || operationType === 'update';
        let confirmText = translate('sulu_admin.ok');

        if (operationType === 'update') {
            confirmText = translate('sulu_admin.save');
        } else if (operationType === 'create') {
            confirmText = translate('sulu_admin.add');
        }

        const cancelText = translate('sulu_admin.cancel');
        const form = <Form onSubmit={this.handleSubmit} ref={this.setFormRef} store={this.formStore} />;

        if (overlayType === 'dialog') {
            return (
                <Dialog
                    cancelText={cancelText}
                    confirmLoading={resourceStore.saving}
                    confirmText={confirmText}
                    onCancel={this.handleClose}
                    onConfirm={this.handleConfirm}
                    open={open}
                    title={this.title}
                >
                    {form}
                </Dialog>
            );
        }

        return (
            <Overlay
                confirmLoading={resourceStore.saving}
                confirmText={confirmText}
                onClose={this.handleClose}
                onConfirm={this.handleConfirm}
                open={open}
                title={this.title}
            >
                <div className={collectionFormOverlayStyles.overlay}>{form}</div>
            </Overlay>
        );
    }
}

export default CollectionFormOverlay;
