import classNames from 'classnames';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { Portal } from 'react-portal';
import { afterElementsRendered } from 'sulu-admin-bundle/utils/DOM';
import Backdrop from 'sulu-admin-bundle/components/Backdrop';
import Button from 'sulu-admin-bundle/components/Button';
import dialogStyles from 'sulu-admin-bundle/components/Dialog//dialog.scss';
import Snackbar from './Snackbar';

@observer
class Dialog extends React.Component {
    static defaultProps = {
        align: 'center',
        confirmDisabled: false,
        confirmLoading: false,
        snackbarType: 'error',
    };

    @observable open = false;
    @observable visible = false;

    constructor(props) {
        super(props);

        const { open } = this.props;

        this.open = open;
        this.visible = open;
    }

    @action componentDidUpdate(prevProps) {
        const { open } = this.props;

        if (prevProps.open === false && open === true) {
            this.visible = true;
        }

        if (prevProps.open !== open) {
            afterElementsRendered(
                action(() => {
                    this.open = open;
                }),
            );
        }
    }

    @action handleTransitionEnd = () => {
        const { open } = this.props;
        if (!open) {
            this.visible = false;
        }
    };

    render() {
        const {
            align,
            children,
            confirmDisabled,
            cancelText,
            confirmLoading,
            confirmText,
            onCancel,
            onConfirm,
            onSnackbarClick,
            onSnackbarCloseClick,
            size,
            snackbarMessage,
            snackbarType,
            title,
        } = this.props;

        const { open, visible } = this;

        const containerClass = classNames(dialogStyles.dialogContainer, {
            [dialogStyles.open]: open,
        });

        const dialogClass = classNames(dialogStyles.dialog, {
            [dialogStyles[size]]: size,
        });

        const articleStyle = classNames(dialogStyles.article, {
            [dialogStyles[align]]: align,
        });

        return (
            <Fragment>
                {visible && (
                    <Portal>
                        <Backdrop />
                        <div className={containerClass} onTransitionEnd={this.handleTransitionEnd}>
                            <div className={dialogClass}>
                                <section className={dialogStyles.content}>
                                    <div className={dialogStyles.snackbar}>
                                        <Snackbar
                                            message={snackbarMessage || ''}
                                            onClick={onSnackbarClick}
                                            onCloseClick={onSnackbarCloseClick}
                                            type={snackbarType}
                                            visible={!!snackbarMessage}
                                        />
                                    </div>

                                    <header className={dialogStyles.header}>
                                        <span className={dialogStyles.headerItem}>{title}</span>
                                    </header>
                                    <article className={articleStyle}>{children}</article>
                                    <footer className={dialogStyles.footer}>
                                        <Button
                                            disabled={confirmDisabled}
                                            loading={confirmLoading}
                                            onClick={onConfirm}
                                            skin="primary"
                                            className={this.props.confirmButtonClassName}
                                        >
                                            {confirmText}
                                        </Button>
                                        {onCancel && cancelText && (
                                            <Button onClick={onCancel} skin="secondary">
                                                {cancelText}
                                            </Button>
                                        )}
                                    </footer>
                                </section>
                            </div>
                        </div>
                    </Portal>
                )}
            </Fragment>
        );
    }
}

export default Dialog;
