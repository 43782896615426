import { reaction } from 'mobx';
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { SingleAutoComplete } from 'sulu-admin-bundle/containers';
import SingleSelectionStore from 'sulu-admin-bundle/stores/SingleSelectionStore';

export default class DepartmentAutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainWebspace: props.data?.mainWebspace ?? props.router?.attributes?.webspace,
            locale: props.data?.locale ?? props.router?.attributes?.locale,
            key: 0,
        };

        this.createStore(props.value);
    }

    createStore(defaultValue) {
        this.selectionStore = new SingleSelectionStore('department_autocomplete', defaultValue, undefined);
        this.disposeReaction = reaction(
            () =>
                this.selectionStore.item === undefined
                    ? undefined
                    : this.selectionStore.item === null
                      ? null
                      : this.selectionStore.item.id,
            (loadedItemId) => {
                const { onChange, value } = this.props;

                if (value !== loadedItemId) {
                    onChange(loadedItemId, this.selectionStore.item);
                }
            },
        );
    }

    componentDidMount() {
        window.rerenderAutoComplete = (mainWebspace, locale) => {
            this.createStore(undefined);

            this.setState((prevState) => ({
                key: prevState.key + 1,
                mainWebspace,
                locale,
            }));
        };
    }

    componentWillUnmount() {
        delete window.rerenderDepartmentAutoComplete;
    }

    render() {
        const { dataPath, disabled } = this.props;

        return (
            <SingleAutoComplete
                key={`department-${this.state.key}`}
                selectionStore={this.selectionStore}
                searchProperties={['title']}
                displayProperty="title"
                allowAdd
                disabled={disabled}
                id={dataPath}
                options={{
                    employeeWebspace: this.state.mainWebspace,
                    employeeLocale: this.state.locale,
                }}
            />
        );
    }
}
