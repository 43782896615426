import React from 'react';
import { action, computed, intercept, observable } from 'mobx';
import { observer } from 'mobx-react';
import { userStore } from 'sulu-admin-bundle/stores';
import { Tabs } from 'sulu-admin-bundle/views';
import WebspaceSelect from 'sulu-page-bundle/components/WebspaceSelect';
import webspaceStore from 'sulu-page-bundle/stores/webspaceStore';
import webspaceTabsStyles from 'sulu-page-bundle/views/WebspaceTabs/webspaceTabs.scss';

const USER_SETTING_PREFIX = 'sulu_page.webspace_tabs';
const USER_SETTING_WEBSPACE = [USER_SETTING_PREFIX, 'webspace'].join('.');

@observer
class WebspaceTabs extends React.Component {
    webspaceKey = observable.box();
    webspaceDisposer;
    bindWebspaceToRouterDisposer;

    static getDerivedRouteAttributes(route, attributes) {
        const webspace = attributes.webspace
            ? attributes.webspace
            : userStore.getPersistentSetting(USER_SETTING_WEBSPACE);

        return { webspace };
    }

    @computed get webspace() {
        return webspaceStore.getWebspace(this.webspaceKey.get());
    }

    constructor(props) {
        super(props);

        const { router } = this.props;

        this.bindWebspaceToRouter();

        this.webspaceDisposer = intercept(this.webspaceKey, '', (change) => {
            if (!change.newValue) {
                return change;
            }

            userStore.setPersistentSetting(USER_SETTING_WEBSPACE, change.newValue);
            return change;
        });

        this.bindWebspaceToRouterDisposer = router.addUpdateRouteHook(this.bindWebspaceToRouter);
    }

    componentWillUnmount() {
        this.bindWebspaceToRouterDisposer();
        this.webspaceDisposer();
    }

    componentDidUpdate() {
        this.redirectToAvailableWebspace();
    }

    componentDidMount() {
        if (this.webspaceKey.get()) {
            userStore.setPersistentSetting(USER_SETTING_WEBSPACE, this.webspaceKey.get());
        }
        this.redirectToAvailableWebspace();
    }

    redirectToAvailableWebspace = () => {
        const { router } = this.props;
        if (
            router.attributes.locale === 'en' &&
            router.attributes.webspace !== 'energon_holding' &&
            router.attributes.webspace !== 'energon_energy_solutions'
        ) {
            this.handleWebspaceChange(router.attributes.webspace);

            return;
        }

        if (
            this.webspaceKey &&
            this.webspaceKey.get() === 'energon_holding' &&
            window.location.href.includes('/project/')
        ) {
            const anyWebspace = webspaceStore.grantedWebspaces.find((webspace) => {
                return webspace.key !== 'energon_holding' && webspace.key !== 'energon_consulting';
            });

            if (anyWebspace) {
                this.handleWebspaceChange(anyWebspace.key);
            }
        }

        if (
            this.webspaceKey &&
            this.webspaceKey.get() !== 'energon_holding' &&
            (window.location.href.includes('/investment/') || window.location.href.includes('/investment-tag/'))
        ) {
            const energonHolding = webspaceStore.grantedWebspaces.find((webspace) => {
                return webspace.key === 'energon_holding';
            });

            if (energonHolding) {
                this.handleWebspaceChange(energonHolding.key);
            }
        }
    };

    bindWebspaceToRouter = () => {
        const { router } = this.props;
        router.bind('webspace', this.webspaceKey);

        return true;
    };

    @action handleWebspaceChange = (value) => {
        const { router } = this.props;
        const route = router.route;
        const options = { ...router.attributes, webspace: value, locale: 'cs' };
        router.navigate(route.name, options);

        this.webspaceKey.set(value);

        if (window.rerenderAutoComplete) {
            window.rerenderAutoComplete(value, 'cs');
        }
    };

    render() {
        const filteredWebspaces = webspaceStore.grantedWebspaces.filter((webspace) => {
            if (
                (webspace.key === 'energon_holding' || webspace.key === 'energon_consulting') &&
                window.location.href.includes('/project/')
            ) {
                return false;
            }

            if (webspace.key !== 'energon_holding' && window.location.href.includes('/investment/')) {
                return false;
            }

            if (webspace.key !== 'energon_holding' && window.location.href.includes('/investment-tag/')) {
                return false;
            }

            return true;
        });

        return (
            <Tabs
                {...this.props}
                childrenProps={{ webspace: this.webspace, webspaceKey: this.webspaceKey }}
                routeChildren={[]}
                header={
                    <div className={webspaceTabsStyles.webspaceSelect}>
                        <WebspaceSelect onChange={this.handleWebspaceChange} value={this.webspaceKey.get()}>
                            {filteredWebspaces.map((webspace) => (
                                <WebspaceSelect.Item key={webspace.key} value={webspace.key}>
                                    {webspace.name}
                                </WebspaceSelect.Item>
                            ))}
                        </WebspaceSelect>
                    </div>
                }
            />
        );
    }
}

export default WebspaceTabs;
