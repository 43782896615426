import { observer } from 'mobx-react';
import React from 'react';
import Pagination from 'sulu-admin-bundle/components/Pagination';
import { translate } from 'sulu-admin-bundle/utils';
import FlatStructureStrategy from 'sulu-admin-bundle/containers/List/structureStrategies/FlatStructureStrategy';
import DefaultLoadingStrategy from 'sulu-admin-bundle/containers/List/loadingStrategies/DefaultLoadingStrategy';
import AbstractTableAdapter from 'sulu-admin-bundle/containers/List/adapters/AbstractTableAdapter';
import listFieldTransformerRegistry from 'sulu-admin-bundle/containers/List/registries/listFieldTransformerRegistry';
import Table from '../components/Table';

@observer
class MediaTableAdapter extends AbstractTableAdapter {
    static LoadingStrategy = DefaultLoadingStrategy;

    static StructureStrategy = FlatStructureStrategy;

    static icon = 'su-align-justify';

    renderCells(item) {
        const schemaKeys = Object.keys(this.schema);

        return schemaKeys
            .filter((key) => key === 'name' || key === 'title' || key === 'size')
            .map((schemaKey, index) => {
                const transformer = listFieldTransformerRegistry.get(this.schema[schemaKey].type);
                const value = transformer.transform(
                    item[schemaKey],
                    this.schema[schemaKey].transformerTypeParameters,
                    item,
                );

                return (
                    <Table.Cell key={item.id + schemaKey + index} width={this.schema[schemaKey].width}>
                        {value}
                    </Table.Cell>
                );
            });
    }

    getButtons = (item, icon) => {
        const { itemActionsProvider, onItemClick } = this.props;

        const { _permissions: { view: viewPermission = true } = {} } = item || {};

        const buttons = [];

        if (onItemClick) {
            buttons.push({
                disabled: !viewPermission,
                icon: icon ? 'su-pen' : undefined,
                onClick: onItemClick,
            });
        }

        if (itemActionsProvider) {
            buttons.push(...itemActionsProvider(item));
        }

        return buttons;
    };

    renderHeaderCells() {
        const { onSort, sortColumn, sortOrder } = this.props;
        const schemaKeys = Object.keys(this.schema);

        return schemaKeys
            .filter((key) => key === 'name' || key === 'title' || key === 'size')
            .map((schemaKey) => {
                const columnSchema = this.schema[schemaKey];
                let label = columnSchema.label ? columnSchema.label : schemaKey;

                if (schemaKey === 'name') {
                    label = translate('sulu.media_file_name');
                }

                return (
                    <Table.HeaderCell
                        key={schemaKey}
                        name={schemaKey}
                        onClick={columnSchema.sortable ? onSort : undefined}
                        sortOrder={sortColumn === schemaKey ? sortOrder : undefined}
                        width={this.schema[schemaKey].width}
                    >
                        {label}
                    </Table.HeaderCell>
                );
            });
    }

    renderRows() {
        const { data, disabledIds, selections } = this.props;

        return data.map((item) => {
            return (
                <Table.Row
                    buttons={this.getButtons(item, true)}
                    disabled={disabledIds.includes(item.id)}
                    id={item.id}
                    key={item.id}
                    selected={selections.includes(item.id)}
                >
                    {this.renderCells(item)}
                </Table.Row>
            );
        });
    }

    render() {
        const {
            data,
            limit,
            loading,
            onAllSelectionChange,
            onItemSelectionChange,
            onLimitChange,
            onPageChange,
            paginated,
            adapterOptions: { show_header = true, skin = 'dark' } = {},
            page,
            pageCount,
        } = this.props;

        const table = (
            <Table
                buttons={this.getButtons()}
                onAllSelectionChange={onAllSelectionChange}
                onRowSelectionChange={onItemSelectionChange}
                selectMode={onItemSelectionChange ? 'multiple' : undefined}
                skin={skin}
                placeholderText={translate('sulu_admin.no_content')}
            >
                {!show_header ? null : <Table.Header>{this.renderHeaderCells()}</Table.Header>}
                <Table.Body>{this.renderRows()}</Table.Body>
            </Table>
        );

        if (!paginated || (page === 1 && data.length === 0)) {
            return table;
        }

        if (pageCount === undefined) {
            return table;
        }

        return (
            <Pagination
                currentLimit={limit}
                currentPage={page}
                loading={loading}
                onLimitChange={onLimitChange}
                onPageChange={onPageChange}
                totalPages={pageCount}
            >
                {table}
            </Pagination>
        );
    }
}

export default MediaTableAdapter;
